.section-about-1
	transition: background 0s ease-in-out
	@apply overflow-hidden
	.item-col-left
		.format-content
			p
				@apply break-inside-avoid
	.item-col-right,.item-col-left
		@apply xl:text-[1vw] md:text-[2vw] text-[1vw]
		.wrap-items
			display: grid
			grid-template-columns: repeat(3, 1fr)
			aspect-ratio: 1
			grid-template-rows: repeat(3, 1fr)
			grid-column-gap: 0px
			grid-row-gap: 0px
			@apply w-full xl:h-full
			.item
				@apply h-full
				&:nth-child(1)
					grid-area: 1 / 1 / 3 / 3
				&:nth-child(2)
					grid-area: 1 / 3 / 2 / 4
				&:nth-child(3)
					grid-area: 2 / 3 / 3 / 4
				&:nth-child(4)
					grid-area: 3 / 1 / 4 / 3
				&:nth-child(5)
					grid-area: 3 / 3 / 4 / 4
		.item-edge-dragon
			&::before
				content: ''
				background-image: url('../img/home-about-dragon.png')
				background-size: 100% 100%
				@media (max-width: 767.98px)
					width: 65%
					background-position: bottom right
					height: 92%
					content: ""
					background-repeat: no-repeat
					transform: translateY(36%)
				@screen md
					transform: translateY(40%)
				@apply w-[400px] h-[300px] md:em:w-[962px] md:em:h-[581px]
				@apply opacity-60
				@apply absolute right-0 bottom-0
				@apply inline-block


.section-about-2
	min-height: r(627px)
	@apply relative
	@screen lg
		.block-image
			@apply lg:w-5/12 absolute top-0 right-0 h-full

.section-about-4
	@apply relative
	.image-wrapper
		@apply relative rem:h-[780px] overflow-hidden
		.img-item
			@apply relative overflow-hidden
			img
				@apply w-full h-full object-cover duration-500 transition-all overflow-hidden
			&:hover img
				@apply scale-110
		.img-item:nth-child(1)
			@apply absolute rem:top-[0] rem:left-[80px] rem:w-[240px] rem:h-[240px]
		.img-item:nth-child(2)
			@apply absolute rem:top-[75px] rem:right-[70px] rem:w-[320px] rem:h-[320px] z-1
		.img-item:nth-child(3)
			@apply absolute rem:top-[280px] rem:left-[5px] rem:w-[480px] rem:h-[480px]
.section-about-5
	h2
		br
			@apply mb-0

.section-about-6
	.wrapper-year
		.swiper-slide
			transition: opacity 0.3s ease-in-out
			@apply opacity-20 pl-2
			.content
				@apply relative flex flex-col gap-5 border-l border-b-white
				&::before
					@apply content-[""] absolute rem:top-[110px] rem:left-[-3px] bg-Primary-2 rem:w-[6px] rem:h-[6px]
				.year
					@apply title-56 font-bold text-Primary-2
				.caption
					@apply pl-4 text-xl font-bold text-white
				.desc
					@apply pl-4 text-xl font-normal text-white
			&.swiper-slide-active
				opacity: 1
		.wrapper-pagination-year
			@apply pt-10
			ul
				@apply overflow-hidden overflow-x-auto flex items-center justify-center gap-20
				li
					span
						@apply cursor-pointer rem:text-[28px] leading-1.3 text-white font-normal
					&.active
						span
							@apply text-Primary-2
		.indicator-swipe
			@apply bg-Primary-1 right-[115px] lg:right-[20%] rem:bottom-[20%] absolute -translate-y-1/2



.section-about-7
	.list-tab
		@apply mt-8 lg:mt-0 max-w-full
		ul
			@apply flex gap-3 lg:gap-5 items-center overflow-auto
			li
				@apply first:ml-auto last:mr-auto
				&.active
					a
						@apply bg-Primary-1 text-Primary-2
				a
					@apply text-Primary-1 border-solid border border-Primary-1
					@apply font-medium text-lg py-3 px-5 flex-center whitespace-nowrap
					&.active,
					&:hover
						@apply bg-Primary-1 text-Primary-2 transition-all duration-300
	.home-cols-1
		.swiper
			@apply shadow-[4px_4px_32px_16px_rgba(0,0,0,0.08)]
	.wrapper-content
		.content
			@apply rem:min-h-[580px]
			.desc
				@apply rem:max-h-[300px] overflow-auto
				+scroll()
	.wrapper-brand
		.swiper-wrapper
			transition-timing-function: linear
		&.slide-center
			.home-cols-9
				.swiper-wrapper
					@apply justify-center flex gap-5
				.swiper-slide
					@apply rem:max-w-[200px]



