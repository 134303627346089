.section-contact-1
	.list-social
		ul
			@apply flex gap-2 items-center
			li
				background: linear-gradient(180deg, #01A6F0 0%, #01699E 100%)
				@apply rounded-full rem:w-[40px] rem:h-[40px] flex-center gap-3 p-3
				i
					@apply text-Primary-2
				img
					@apply flex-center
.section-contact-2
	.content
		@apply pr-5 rem:max-h-[400px] lg:rem:max-h-[820px] overflow-y-scroll
		&::-webkit-scrollbar-track
			-webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.2)
			@apply bg-neutral-100 rounded-2.5
		&::-webkit-scrollbar
			@apply bg-neutral-100 rem:w-[8px]
		&::-webkit-scrollbar-thumb
			-webkit-box-shadow: inset 0 0 2px rgba(0,0,0,.2)
			@apply bg-Primary-1 rounded-2.5
		.box-item
			@apply transition-all duration-300 cursor-pointer flex flex-col gap-2 bg-neutral-50 p-6
			&.active
				@apply bg-Primary-1
				.list-address
					.item-address
						.icon
							i
								@apply text-Primary-2
						a
							@apply text-white
				.title
					@apply text-white
			.title
				@apply text-Primary-1
		.list-address
			@apply flex flex-col gap-2
			.item-address
				@apply flex items-start gap-2
				.icon
					@apply text-Primary-1
					i
						@apply font-normal
				a
					@apply text-lg font-medium text-neutral-950
	.block-map
		@apply hidden
		iframe
			@apply w-full rem:h-[500px] lg:rem:h-[820px]
		&.active
			@apply block

.popup-modal
	// transform: translate(-50%, -50%)
	@apply bg-white rem:max-w-[900px] w-full p-10 lg:p-20
	.carousel__button.is-close
		background: linear-gradient(180deg, #01A6F0 0%, #01699E 100%)
		@apply cursor-pointer p-3 rem:w-[40px] flex-center gap-2 top-0 rounded-none
	.popup-modal-wrapper
		form
			@apply grid md:grid-cols-2 lg:gap-6 gap-2
		label
			@apply hidden
		.f-message
			@apply md:col-span-2
		input
			@apply p-3 outline-none w-full border-solid border border-[#DCDCDC]
		textarea
			@apply p-5 w-full outline-none border-solid border border-[#DCDCDC]

@media (max-width: 1279.98px)
	.popup-modal
		@apply max-w-[95vw]
#gva-overlay
	background: rgba(0, 0, 0, 0.6)
	@apply fixed w-full h-full top-0 left-0 z-1 hidden cursor-pointer
