footer
	.footer-desc
		@apply text-lg
	.footer-slogan
		.text-stroke
			-webkit-background-clip: text !important
			font-weight: 700
			letter-spacing: rr(-3.84px)
			text-transform: uppercase
			-webkit-text-fill-color: transparent
			-webkit-text-stroke-color: white
			-webkit-text-stroke-width: .1041666667rem
		.title
			letter-spacing: rr(-1.44px)
			@apply md:text-[46px] lg:rem:text-[72px] font-bold leading-none text-[30px]
	.list-footer
		li
			a
				+hover-underline(95%, #ffde00)
				@apply hover:text-Primary-2 relative py-2 text-lg
	.footer-menu
		.col-footer
			.title-footer
				@apply md:px-5 lg:py-6 py-2
			.wrap-footer-content
				@apply md:px-5 lg:py-6 py-2
	.footer-social
		@apply flex items-center gap-2 mt-6
		li
			a
				@apply bg-white rounded-full w-10 h-10 flex-center transition-all duration-300 hover:bg-Primary-1
				i
					@apply text-Primary-7
	.wrap-footer-form
		@apply flex border border-white
		.wpcf7-form-control-wrap
			@apply flex-1
		input
			@apply h-10 px-5 bg-white/10 placeholder:text-white/80 text-white outline-none min-w-[1px] w-full text-sm
		button
			@apply text-0  bg-white/10 w-10 flex-center flex-shrink-0
			&::before
				content: '\f178'
				@apply font-awesome  text-Primary-2 text-xl font-light
	.footer-copyright
		img
			@apply rem:h-[160px]
		.footer-list-link
			li
				&+li
					&::before
						content: ''
						@apply w-[1px] h-3 bg-white/20 inline-block mx-3
.tool-hotline
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1)
	animation: pulse-red 2s infinite
	@apply fixed bottom-5 right-5 z-50 w-[55px] h-[55px] bg-Primary-1 rounded-full flex-center text-white
	@apply text-2xl md:hidden
	i
		animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out
@keyframes coccoc-alo-circle-img-anim
	0%
		transform: rotate(0) scale(1) skew(1deg)
	10%
		transform: rotate(-25deg) scale(1) skew(1deg)
	20%
		transform: rotate(25deg) scale(1) skew(1deg)
	30%
		transform: rotate(-25deg) scale(1) skew(1deg)
	40%
		transform: rotate(25deg) scale(1) skew(1deg)
	50%
		transform: rotate(0) scale(1) skew(1deg)
	100%
		transform: rotate(0) scale(1) skew(1deg)
@keyframes pulse-red
	0%
		transform: scale(0.95)
		box-shadow: 0 0 0 0 rgba(0, 183, 241, 0.6)
	70%
		transform: scale(1)
		box-shadow: 0 0 0 10px rgba(255, 82, 82, 0)
	100%
		transform: scale(0.95)
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0)
