@charset "UTF-8";
.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900);
}

.fa, .fas, .fa-solid, .far, .fa-regular, .fal, .fa-light, .fat, .fa-thin, .fad, .fa-duotone, .fab, .fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
}

::root, ::host {
  --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands";
}

@font-face {
  font-family: "Font Awesome 6 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fa-brands-400.woff2") format("woff2"), url("../fonts/fa-brands-400.ttf") format("truetype");
}
.fab,
.fa-brands {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

::root, ::host {
  --fa-font-light: normal 300 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("../fonts/fa-light-300.woff2") format("woff2"), url("../fonts/fa-light-300.ttf") format("truetype");
}
.fal,
.fa-light {
  font-family: "Font Awesome 6 Pro";
  font-weight: 300;
}

::root, ::host {
  --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fa-regular-400.woff2") format("woff2"), url("../fonts/fa-regular-400.ttf") format("truetype");
}
.far,
.fa-regular {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

::root, ::host {
  --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../fonts/fa-solid-900.woff2") format("woff2"), url("../fonts/fa-solid-900.ttf") format("truetype");
}
.fas,
.fa-solid {
  font-family: "Font Awesome 6 Pro";
  font-weight: 900;
}

::root, ::host {
  --fa-font-thin: normal 100 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url("../fonts/fa-thin-100.woff2") format("woff2"), url("../fonts/fa-thin-100.ttf") format("truetype");
}
.fat,
.fa-thin {
  font-family: "Font Awesome 6 Pro";
  font-weight: 100;
}

@font-face {
  font-family: "SVN-Gilroy";
  src: url("../fonts/SVN-GilroyBlack.woff2") format("woff2"), url("../fonts/SVN-GilroyBlack.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SVN-Gilroy";
  src: url("../fonts/SVN-GilroyBlackItalic.woff2") format("woff2"), url("../fonts/SVN-GilroyBlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "SVN-Gilroy";
  src: url("../fonts/SVN-GilroyBoldItalic.woff2") format("woff2"), url("../fonts/SVN-GilroyBoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "SVN-Gilroy";
  src: url("../fonts/SVN-GilroyLight.woff2") format("woff2"), url("../fonts/SVN-GilroyLight.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SVN-Gilroy";
  src: url("../fonts/SVN-Gilroy.woff2") format("woff2"), url("../fonts/SVN-Gilroy.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SVN-Gilroy";
  src: url("../fonts/SVN-GilroyHeavy.woff2") format("woff2"), url("../fonts/SVN-GilroyHeavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SVN-Gilroy";
  src: url("../fonts/SVN-GilroyHeavyItalic.woff2") format("woff2"), url("../fonts/SVN-GilroyHeavyItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "SVN-Gilroy";
  src: url("../fonts/SVN-GilroyItalic.woff2") format("woff2"), url("../fonts/SVN-GilroyItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "SVN-Gilroy";
  src: url("../fonts/SVN-GilroyLightItalic.woff2") format("woff2"), url("../fonts/SVN-GilroyLightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "SVN-Gilroy";
  src: url("../fonts/SVN-GilroyBold.woff2") format("woff2"), url("../fonts/SVN-GilroyBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SVN-Gilroy";
  src: url("../fonts/SVN-GilroyMedium.woff2") format("woff2"), url("../fonts/SVN-GilroyMedium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SVN-Gilroy";
  src: url("../fonts/SVN-GilroyMediumItalic.woff2") format("woff2"), url("../fonts/SVN-GilroyMediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "SVN-Gilroy";
  src: url("../fonts/SVN-GilroySemiBoldItalic.woff2") format("woff2"), url("../fonts/SVN-GilroySemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "SVN-Gilroy";
  src: url("../fonts/SVN-GilroySemiBold.woff2") format("woff2"), url("../fonts/SVN-GilroySemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SVN-Gilroy";
  src: url("../fonts/SVN-GilroyThin.woff2") format("woff2"), url("../fonts/SVN-GilroyThin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SVN-Gilroy XBold";
  src: url("../fonts/SVN-GilroyXBoldItalic.woff2") format("woff2"), url("../fonts/SVN-GilroyXBoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "SVN-Gilroy";
  src: url("../fonts/SVN-GilroyThinItalic.woff2") format("woff2"), url("../fonts/SVN-GilroyThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "SVN-Gilroy XBold";
  src: url("../fonts/SVN-GilroyXBold.woff2") format("woff2"), url("../fonts/SVN-GilroyXBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SVN-Gilroy XLight";
  src: url("../fonts/SVN-GilroyXLightItalic.woff2") format("woff2"), url("../fonts/SVN-GilroyXLightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "SVN-Gilroy XLight";
  src: url("../fonts/SVN-GilroyXLight.woff2") format("woff2"), url("../fonts/SVN-GilroyXLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
form.invalid .wpcf7-response-output {
  @apply text-red-600 border-red-600 bg-white !important;
}

.wpcf7-response-output {
  @apply text-green-400 border-green-400 bg-white border py-1 px-4 text-center mt-2 mb-0 ml-0 mr-0 text-sm !important;
}

.form-group {
  @apply relative;
}
.form-group .wpcf7-not-valid-tip {
  @apply absolute top-1/2 -translate-y-1/2 right-3 text-xs text-red-600;
}

.frm-submit {
  @apply relative;
}
.frm-submit .wpcf7-spinner {
  @apply absolute left-full top-1/2 -translate-y-1/2 ml-2;
}

.edit-link i:before {
  content: "✏️";
}

.wrap-button-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  @apply gap-5 xl:gap-0;
}
@media (max-width: 1023.98px) {
  .wrap-button-slide.none-abs {
    @apply mt-5;
  }
  .wrap-button-slide.none-abs .btn-prev, .wrap-button-slide.none-abs .btn-next {
    @apply static translate-y-0 !important;
  }
}
@screen lg {
  .wrap-button-slide.none-abs .btn-prev, .wrap-button-slide.none-abs .btn-next {
    @apply absolute top-1/2 -translate-y-1/2;
  }
  .wrap-button-slide.none-abs .btn-prev {
    @apply left-0 ml-5 lg:ml-10 right-auto !important;
  }
  .wrap-button-slide.none-abs .btn-next {
    @apply right-0 mr-5 lg:mr-10 left-auto !important;
  }
}
.wrap-button-slide.in-static .btn-prev, .wrap-button-slide.in-static .btn-next {
  @apply absolute top-1/2 -translate-y-1/2;
}
.wrap-button-slide.in-static .btn-prev {
  @apply left-0 ml-5 lg:ml-10 right-auto !important;
}
.wrap-button-slide.in-static .btn-next {
  @apply right-0 mr-5 lg:mr-10 left-auto !important;
}
.wrap-button-slide.none-static .btn-prev, .wrap-button-slide.none-static .btn-next {
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 1279.98px) {
  .wrap-button-slide.none-static .btn-prev, .wrap-button-slide.none-static .btn-next {
    margin: 0 !important;
  }
}
@media (max-width: 1023.98px) {
  .wrap-button-slide.none-static .btn-prev {
    right: 94%;
  }
}
@media (max-width: 575.98px) {
  .wrap-button-slide.none-static .btn-prev {
    right: auto;
    left: 10px;
  }
}
@media (max-width: 1023.98px) {
  .wrap-button-slide.none-static .btn-next {
    left: 94%;
  }
}
@media (max-width: 575.98px) {
  .wrap-button-slide.none-static .btn-next {
    left: auto;
    right: 10px;
  }
}
.wrap-button-slide .btn-prev, .wrap-button-slide .btn-next {
  z-index: 9;
  cursor: pointer;
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
}
.wrap-button-slide .btn-prev.swiper-button-lock, .wrap-button-slide .btn-next.swiper-button-lock {
  @apply hidden;
}
.wrap-button-slide .btn-prev {
  left: 15px;
}
@screen xl {
  .wrap-button-slide .btn-prev {
    margin-right: 1.5625rem;
    right: 100%;
    left: auto;
  }
}
.wrap-button-slide .btn-next {
  right: 15px;
}
@screen xl {
  .wrap-button-slide .btn-next {
    margin-left: 1.5625rem;
    left: 100%;
    right: auto;
  }
}

@screen xl {
  .swiper-full-current > .swiper-wrapper > .swiper-slide .animation-fadeIn, .swiper-full-current > .swiper-wrapper > .swiper-slide .animation-fadeDown, .swiper-full-current > .swiper-wrapper > .swiper-slide .animation-fadeLeft, .swiper-full-current > .swiper-wrapper > .swiper-slide .animation-fadeRight {
    animation: fadeClose 1s ease-out 0.5s both;
  }
  .swiper-full-current > .swiper-wrapper > .swiper-slide.swiper-slide-active .animation-fadeIn {
    animation: fadeIn 0.75s ease-out 0.5s both;
  }
  .swiper-full-current > .swiper-wrapper > .swiper-slide.swiper-slide-active .animation-fadeRight {
    animation: fadeRight 0.75s ease-out 0.5s both;
  }
  .swiper-full-current > .swiper-wrapper > .swiper-slide.swiper-slide-active .animation-fadeLeft {
    animation: fadeLeft 0.75s ease-out 0.5s both;
  }
  .swiper-full-current > .swiper-wrapper > .swiper-slide.swiper-slide-active .animation-fadeDown {
    animation: fadeDown 0.75s ease-out 0.5s both;
  }
  .swiper-full-current > .swiper-wrapper > .swiper-slide.swiper-slide-active .delay-0 {
    animation-delay: 0s;
  }
  .swiper-full-current > .swiper-wrapper > .swiper-slide.swiper-slide-active .delay-1 {
    animation-delay: 0.5s;
  }
  .swiper-full-current > .swiper-wrapper > .swiper-slide.swiper-slide-active .delay-2 {
    animation-delay: 0.8s;
  }
  .swiper-full-current > .swiper-wrapper > .swiper-slide.swiper-slide-active .delay-3 {
    animation-delay: 1.1s;
  }
  .swiper-full-current > .swiper-wrapper > .swiper-slide.swiper-slide-active .delay-4 {
    animation-delay: 1.4s;
  }
  .swiper-full-current > .swiper-wrapper > .swiper-slide.swiper-slide-active .delay-5 {
    animation-delay: 1.7s;
  }
  .swiper-full-current > .swiper-wrapper > .swiper-slide.swiper-slide-active .delay-6 {
    animation-delay: 2s;
  }
}

body.fullpage {
  @apply xl:overflow-hidden;
}

@screen xl {
  .fullpage-swiper > .swiper {
    height: 100vh;
  }
  .fullpage-swiper > .swiper .section-swiper {
    height: 100vh;
    @apply opacity-0 pt-20;
    @apply pb-0;
  }
  .fullpage-swiper > .swiper .swiper-slide .section-swiper {
    @apply opacity-0 transition-all duration-1000;
  }
  .fullpage-swiper > .swiper .swiper-slide.swiper-slide-active .section-swiper {
    @apply opacity-100;
  }
}
.fullpage-swiper > .swiper > .swiper-wrapper {
  display: block;
}
@screen xl {
  .fullpage-swiper > .swiper > .swiper-wrapper {
    display: flex;
  }
}

@keyframes fadeClose {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeDown {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadeRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.zoom-img {
  overflow: hidden;
}
.zoom-img:hover img {
  transform: scale(1.05) translateZ(0);
}
.zoom-img img {
  transition: 0.5s all ease-in-out !important;
}

.ring-food {
  animation: 1.5s ring-food infinite ease-in-out alternate;
}

.ring-chili {
  animation: 1.5s ring-chili infinite ease-in-out alternate;
}

.ring-food-footer {
  animation: 1.5s ring-food-footer infinite ease-in-out alternate;
}

@keyframes ring-chili {
  0% {
    transform: rotate(8deg);
  }
  100% {
    transform: rotate(-8deg);
  }
}
@keyframes ring-food-footer {
  0% {
    transform: rotate(4deg);
  }
  100% {
    transform: rotate(-4deg);
  }
}
@keyframes ring-food {
  0% {
    transform: rotate(300deg);
  }
  100% {
    transform: rotate(330deg);
  }
}
@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fullpage-slide-thumb {
  @apply fixed right-5 top-1/2 -translate-y-1/2 z-10 hidden xl:block;
}
.fullpage-slide-thumb.color-white .swiper .item-thumb {
  @apply text-white;
}
.fullpage-slide-thumb .swiper, .fullpage-slide-thumb .swiper-wrapper {
  @apply h-[auto] overflow-visible;
}
.fullpage-slide-thumb .swiper .swiper-slide, .fullpage-slide-thumb .swiper-wrapper .swiper-slide {
  @apply rem:h-[46px] rem:w-[46px] mb-5 overflow-visible cursor-pointer !important;
}
.fullpage-slide-thumb .swiper .swiper-slide:hover .item-thumb, .fullpage-slide-thumb .swiper-wrapper .swiper-slide:hover .item-thumb {
  @apply opacity-100 bg-Primary-3 border-Primary-2 text-Primary-2 border;
}
.fullpage-slide-thumb .swiper .swiper-slide.swiper-slide-thumb-active .item-thumb, .fullpage-slide-thumb .swiper-wrapper .swiper-slide.swiper-slide-thumb-active .item-thumb {
  @apply opacity-100 bg-Primary-3 border-Primary-2 text-Primary-2 border;
}
.fullpage-slide-thumb .swiper .item-thumb, .fullpage-slide-thumb .swiper-wrapper .item-thumb {
  @apply w-full h-full rounded-full text-0 pointer-events-none transition-all duration-300 text-lg opacity-50 bg-transparent flex-center text-Primary-3;
}

span.fa-exclamation-triangle {
  @apply text-red-500 text-sm font-primary py-2 !important;
}
span.fa-exclamation-triangle::before {
  display: none;
}

.btn.btn-default.frm-btn-reset {
  @apply hidden;
}

.frm-btnwrap, .frm-captcha {
  opacity: 0;
}

.wrap-form-submit {
  @apply flex items-center flex-wrap justify-between gap-5 md:gap-4;
}
.wrap-form-submit .frm-captcha, .wrap-form-submit .frm-btnwrap {
  opacity: 1;
}
.wrap-form-submit .frm-captcha {
  @apply flex flex-row-reverse items-center flex-1;
}
.wrap-form-submit .frm-captcha .RadCaptcha {
  width: fit-content !important;
  @apply relative;
}
.wrap-form-submit .frm-captcha .RadCaptcha > span {
  @apply absolute top-full left-0 text-red-500;
}
.wrap-form-submit .frm-captcha .RadCaptcha > div > div {
  @apply flex items-center;
}
.wrap-form-submit .frm-captcha .RadCaptcha img {
  width: 8.28125rem !important;
  height: 2.6041666667rem !important;
  @apply border border-[#EEEEEE] !important;
}
.wrap-form-submit .frm-captcha .RadCaptcha a.rcRefreshImage {
  @apply text-0 before:content-[""] before:font-awesome before:text-2xl before:mx-3 before:inline-block w-full h-full text-[#333333];
  @apply flex-center !important;
}
.wrap-form-submit .frm-captcha .frm-captcha-input {
  @apply flex-1;
}
.wrap-form-submit .frm-captcha .frm-captcha-input label {
  @apply hidden;
}
@media (max-width: 767.98px) {
  .wrap-form-submit .frm-btnwrap {
    @apply flex-[0_0_100%];
  }
}
.wrap-form-submit .frm-btnwrap label {
  @apply hidden;
}

.search-page {
  padding: 40px 0;
}
.search-page h1 {
  letter-spacing: -2px;
  margin-bottom: 20px;
  font-size: 45px;
  line-height: 55px;
  font-weight: 500;
  color: #333333;
}
@media (max-width: 1024px) {
  .search-page h1 {
    font-size: 24px;
    line-height: 30px;
  }
}
.search-page h1.center {
  text-align: center;
}
.search-page .btn-reindex {
  display: none;
}
.search-page .searchcontrols .form-group {
  position: static;
}
.search-page .searchcontrols .form-inline {
  position: relative;
}
.search-page .searchcontrols .form-inline:before {
  content: "";
  background-image: url("/Data/Sites/1/skins/default/img/icon/search.svg");
  @apply absolute w-6 h-6 flex-center top-1/2 right-4 brightness-0 -translate-y-1/2;
}
.search-page .searchcontrols .frm-btn {
  position: absolute;
  top: 0;
  right: 10px;
  background-color: rgba(0, 0, 0, 0);
  z-index: 2;
}
.search-page input[type=text] {
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 54px;
  border: 1px solid #dfe1e5;
  background-color: #fff;
  color: #333;
  font-size: 0.875rem;
  font-weight: 400;
  border-radius: 22px;
  box-shadow: 0 0 0 0px rgba(0, 0, 0, 0);
  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
  @apply h-12.5;
}
.search-page input[type=submit] {
  @apply absolute top-0 right-0 h-full flex-center px-4 h-12.5 text-0;
}
.search-page .text-danger {
  margin-top: 20px;
  font-size: 15px;
  color: #333;
  font-style: italic;
  font-weight: 600;
}
.search-page .searchresultsummary {
  margin-bottom: 20px;
}
.search-page .searchresults {
  margin-top: 30px;
}
.search-page .searchresults .modulepager:first-child {
  display: none;
}
.search-page .searchresultlist {
  margin-bottom: 20px;
}
.search-page .searchresult {
  margin-bottom: 30px;
  box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
  padding: 16px;
  border-radius: 8px;
}
.search-page .searchresult:last-child {
  margin-bottom: 0;
}
.search-page .searchresult h3 {
  font-size: 18px;
  line-height: 1.33;
  font-weight: 400;
  margin-bottom: 10px;
}
.search-page .searchresult h3 a {
  color: #1A0DAB;
  text-decoration: none;
  font-weight: 500;
}
.search-page .searchresult h3 a:hover {
  text-decoration: underline;
}
.search-page .searchresult .searchresultdesc {
  color: #545454;
  line-height: 1.54;
  word-wrap: break-word;
  font-size: small;
  font-weight: 400;
  margin-bottom: 10px;
}
.search-page .searchresult .searchterm {
  color: #6a6a6a;
  font-weight: bold;
  font-style: normal;
  line-height: 1.54;
  word-wrap: break-word;
  font-size: small;
}
.search-page .searchresult hr {
  display: none !important;
}

.loginpage header {
  display: none;
}
.loginpage footer {
  display: none;
}
.loginpage main {
  background-color: #F0F0F1;
  display: table-cell;
  vertical-align: middle;
  height: 100vh;
  width: 100vw;
  padding-top: 0 !important;
  padding: 0 15px;
}
.loginpage .header-tools-mobile {
  display: none !important;
}
.loginpage .login-box {
  max-width: 690px;
  margin: 0 auto;
  width: 100%;
}
.loginpage .login-box .login-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  flex-direction: column;
  font-size: 0;
  margin-bottom: 30px;
}
.loginpage .login-box .login-logo::before {
  content: "";
  display: inline-block;
  background-image: url("../img/logo-canh-cam.png");
  width: 267px;
  height: 49px;
  background-repeat: no-repeat;
  background-size: 267px auto;
  margin-bottom: 15px;
}
.loginpage .login-box .loginstandard {
  background-color: #fff;
  box-shadow: 0, 4px, 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: 0;
  padding: 40px 60px 30px;
  font-weight: 400;
  overflow: hidden;
  font-family: Roboto, sans-serif !important;
}
@media (max-width: 767.98px) {
  .loginpage .login-box .loginstandard {
    padding: 15px 20px;
  }
}
.loginpage .login-box .loginstandard .card-body .row.items-center {
  @apply flex-wrap mx-0;
}
.loginpage .login-box .loginstandard .card-body .row.items-center .col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.loginpage .login-box .loginstandard .card-body .login-box-msg {
  display: none;
}
.loginpage .login-box .loginstandard .card-body .panel-body .input-group {
  position: relative;
  margin-bottom: 1.25rem;
}
.loginpage .login-box .loginstandard .card-body .panel-body .input-group .input-group-append {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  color: #d1d1d1;
}
.loginpage .login-box .loginstandard .card-body .panel-body .input-group input {
  background: #f5f5f5;
  border-radius: 25px;
  height: 50px;
  border: 0;
  outline: none;
  box-shadow: 0 0 0 1px #f5f5f5;
  transition: 0.3s all ease-in-out;
  padding: 0 35px;
  font-size: 14px;
  width: 100%;
  border: 1px solid transparent;
}
.loginpage .login-box .loginstandard .card-body .panel-body .input-group input:hover, .loginpage .login-box .loginstandard .card-body .panel-body .input-group input:focus {
  border: 1px solid #f73936;
}
.loginpage .login-box .loginstandard .card-body .panel-body .icheck-primary {
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 10px;
  margin-bottom: 20px;
}
.loginpage .login-box .loginstandard .card-body .panel-body .btn-primary {
  background-color: #f73936;
  border-radius: 25px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: bold;
  width: 100%;
  font-size: 20px;
  border: 0;
  outline: none;
  transition: 0.3s all ease-in-out;
}
.loginpage .login-box .loginstandard .card-body .panel-body .flex.justify-between.mt-3 {
  margin-top: 30px;
  font-size: 14px;
}
.loginpage .login-box .loginstandard .card-body .panel-body .cart-login {
  display: none;
}
.loginpage .login-box .loginstandard .card-body span.alert {
  color: #f73936;
  display: block;
  margin-bottom: 10px;
  text-align: center;
}

.sitemap {
  @apply py-15;
}

.sitemap-heading h1 {
  @apply text-lg text-center uppercase mx-auto;
  @apply relative w-max max-w-full pb-1.5 before:absolute before:bottom-0 before:left-0 before:w-full before:h-[1px] before:bg-white;
}

.AspNet-TreeView > ul {
  @apply sm:flex sm:flex-wrap xl:overflow-hidden;
}
.AspNet-TreeView .AspNet-TreeView-Root {
  @apply pb-5 ml-2 first:ml-0 xl:ml-0 xl:pr-5 xl:relative xl:overflow-hidden;
}
.AspNet-TreeView .AspNet-TreeView-Root a {
  @apply inline-flex items-center px-7 py-3 text-xl bg-blue-400 text-white rounded-full max-w-full relative z-2 xl:text-sm xl:px-5 xl:hover:bg-red-300;
}
.AspNet-TreeView .AspNet-TreeView-Root > a {
  @apply before:absolute before:left-full before:w-[120%] before:h-[1px] before:bg-gray-300 before:-z-1;
}
.AspNet-TreeView .AspNet-TreeView-Root .AspNet-TreeView-Collapse {
  @apply hidden;
}
.AspNet-TreeView .AspNet-TreeView-Root li {
  @apply pl-3;
}
.AspNet-TreeView .AspNet-TreeView-Root li a {
  @apply bg-blue-400/[.9];
}
.AspNet-TreeView .AspNet-TreeView-Root li li a {
  @apply bg-blue-400/[.75];
}
.AspNet-TreeView .AspNet-TreeView-Root li li li a {
  @apply bg-blue-400/[.6];
}
.AspNet-TreeView .AspNet-TreeView-Root > ul > li:last-child {
  @apply before:absolute before:w-[1px] before:h-full before:left-0 before:top-[65%] before:-translate-y-1/2 before:bg-white before:z-1 before:pointer-events-none;
}
.AspNet-TreeView .AspNet-TreeView-Root > ul > li.AspNet-TreeView-Leaf:last-child {
  @apply before:top-full;
}
.AspNet-TreeView .AspNet-TreeView-Root:last-child > a {
  @apply before:hidden;
}
.AspNet-TreeView .AspNet-TreeView-Root > ul, .AspNet-TreeView .AspNet-TreeView-Leaf > ul, .AspNet-TreeView .AspNet-TreeView-Parent > ul {
  @apply ml-5 overflow-hidden;
}
.AspNet-TreeView .AspNet-TreeView-Root > ul > li, .AspNet-TreeView .AspNet-TreeView-Leaf > ul > li, .AspNet-TreeView .AspNet-TreeView-Parent > ul > li {
  @apply mt-2 relative z-1;
  @apply after:absolute after:w-[1px] after:h-[200%] after:left-0 after:top-1/2 after:-translate-y-full after:bg-gray-300 after:pointer-events-none;
}
.AspNet-TreeView .AspNet-TreeView-Root > ul > li > a, .AspNet-TreeView .AspNet-TreeView-Leaf > ul > li > a, .AspNet-TreeView .AspNet-TreeView-Parent > ul > li > a {
  @apply relative;
  @apply before:absolute before:w-3 before:-z-1 before:h-[1px] before:bg-gray-300 before:top-1/2 before:-translate-y-1/2 before:right-full before:pointer-events-none;
}

.cmsadminpanel ul {
  display: flex;
  align-items: center;
}
.cmsadminpanel.isClose .navbar-nav {
  @apply hidden;
}
.cmsadminpanel.isClose #pullmenucms i::after {
  content: "Mở";
}
.cmsadminpanel #pullmenucms i::after {
  content: "Đóng";
}

.notfound .notfound-404 h1 {
  @apply bg-red-500 bg-none !important;
}
.notfound a {
  @apply bg-red-500 bg-none shadow-none !important;
}

.btn {
  @apply cursor-pointer select-none transition-all duration-300;
}
.btn.btn-primary {
  @apply h-12 flex-center px-3 py-3 text-lg font-semibold text-white gap-2 border border-white bg-transparent;
}
.btn.btn-primary.outline {
  @apply outline-none !important;
  @apply bg-transparent text-Primary-1 border-Primary-1 hover:border-Primary-2 hover:text-Primary-2 !important;
}
.btn.btn-primary.outline:hover .icon-arrow::before, .btn.btn-primary.outline:hover .icon-arrow::after {
  @apply text-Primary-2;
}
.btn.btn-primary.outline .icon-arrow::before, .btn.btn-primary.outline .icon-arrow::after {
  @apply text-Primary-1;
}
.btn.btn-primary.blue {
  @apply bg-Primary-1 text-white border-Primary-1 hover:border-Primary-3 hover:bg-Primary-3 !important;
}
.btn.btn-primary.blue .icon-arrow::before, .btn.btn-primary.blue .icon-arrow::after {
  @apply text-white;
}
.btn.btn-primary.secondary {
  @apply bg-transparent text-Primary-1 border-Primary-1 hover:border-Primary-2 hover:text-Primary-2 !important;
}
.btn.btn-primary.secondary:hover .icon-arrow::before, .btn.btn-primary.secondary:hover .icon-arrow::after {
  @apply text-Primary-2;
}
.btn.btn-primary.secondary .icon-arrow::before, .btn.btn-primary.secondary .icon-arrow::after {
  @apply text-Primary-1;
}
.btn.btn-primary:hover {
  @apply border-Primary-2 text-Primary-2;
}
.btn.btn-primary:hover .icon-arrow::before {
  @apply translate-x-[125%];
}
.btn.btn-primary:hover .icon-arrow::after {
  @apply translate-x-0;
}
.btn.btn-primary .icon-arrow {
  @apply flex-center w-6 h-6 overflow-hidden relative;
  @apply rem:pt-[3px];
}
.btn.btn-primary .icon-arrow::before {
  @apply translate-x-0;
}
.btn.btn-primary .icon-arrow::after {
  @apply -translate-x-[125%];
}
.btn.btn-primary .icon-arrow::before, .btn.btn-primary .icon-arrow::after {
  content: "\f178";
  display: inline-block;
  @apply font-light;
  @apply absolute inline-block font-awesome transition-all duration-300 text-Primary-2;
}
.btn.btn-sw-1 {
  @apply w-16 h-16 flex-center rounded-full bg-Primary-3 hover:border-Primary-2 border-transparent border transition-all duration-300;
}
.btn.btn-sw-1::before, .btn.btn-sw-1::after {
  @apply font-awesome text-2xl text-white font-light;
}
.btn.btn-sw-1.btn-prev::before {
  content: "\f053";
}
.btn.btn-sw-1.btn-next::before {
  content: "\f054";
}
.btn.btn-sw-2 {
  background: linear-gradient(67deg, #9FA1A3 29.78%, #FFF 54.04%, #CBCCCD 70.22%);
  @apply w-12 h-12 flex-center transition-all duration-300;
  @apply relative;
}
.btn.btn-sw-2:hover {
  background: linear-gradient(67deg, #ffde00 29.78%, #ffde00 54.04%, #ffde00 70.22%);
}
.btn.btn-sw-2:hover::before {
  @apply text-white;
}
.btn.btn-sw-2:hover::after {
  background-color: #ffde00;
}
.btn.btn-sw-2::after {
  @apply content-[""] absolute top-[2px] left-[2px] bottom-[2px] right-[2px] bg-white z-1 transition-all duration-300;
}
.btn.btn-sw-2::before {
  @apply font-awesome text-2xl text-Neutral-300 font-light relative z-2 transition-all duration-300;
}
.btn.btn-sw-2.btn-prev::before {
  content: "\f053";
}
.btn.btn-sw-2.btn-next::before {
  content: "\f054";
}
.btn.btn-career {
  @apply h-12 flex-center px-3 py-3 text-lg font-semibold text-white gap-2 border border-white bg-transparent;
}
.btn.btn-career.outline {
  @apply bg-transparent text-Primary-1 border-Primary-1 hover:border-Primary-2 hover:text-Primary-2 !important;
}
.btn.btn-career.outline:hover .icon-arrow::before, .btn.btn-career.outline:hover .icon-arrow::after {
  @apply text-Primary-2;
}
.btn.btn-career.outline .icon-arrow::before, .btn.btn-career.outline .icon-arrow::after {
  @apply text-Primary-1;
}
.btn.btn-career.blue {
  @apply bg-Primary-1 text-white border-Primary-1 hover:border-Primary-3 hover:bg-Primary-3 !important;
}
.btn.btn-career.blue .icon-arrow::before, .btn.btn-career.blue .icon-arrow::after {
  @apply text-white;
}
.btn.btn-career:hover {
  @apply border-Primary-2 text-Primary-2;
}
.btn.btn-career:hover .icon-arrow::before {
  @apply translate-x-[125%];
}
.btn.btn-career:hover .icon-arrow::after {
  @apply translate-x-0;
}
.btn.btn-career .icon-arrow {
  @apply flex-center w-6 h-6 overflow-hidden relative;
  @apply rem:pt-[3px];
}
.btn.btn-career .icon-arrow::before {
  @apply translate-x-0;
}
.btn.btn-career .icon-arrow::after {
  @apply -translate-x-[125%];
}
.btn.btn-career .icon-arrow::before, .btn.btn-career .icon-arrow::after {
  content: "\f178";
  display: inline-block;
  @apply font-light;
  @apply absolute inline-block font-awesome transition-all duration-300 text-Primary-2;
}
.btn.btn-profile {
  @apply py-3 px-5 flex-center gap-2 border-solid border-Primary-1 border;
  @apply transition-all duration-500;
}
.btn.btn-profile:hover {
  @apply bg-Primary-1;
}
.btn.btn-profile:hover span,
.btn.btn-profile:hover .icon-profile {
  @apply text-white;
}
.btn.btn-profile span {
  @apply text-Primary-1 text-lg font-semibold;
}
.btn.btn-profile .icon-profile {
  @apply text-Primary-1;
}
.btn.btn-profile .icon-profile::before {
  content: "\f019";
  @apply inline-block font-awesome;
}

.btn-style-main {
  @apply w-full lg:w-auto cursor-pointer select-none transition-all duration-300;
  @apply bg-Primary-1;
}
.btn-style-main.btn-primary {
  @apply flex justify-center gap-2 relative z-2 py-3 px-5  w-fit overflow-hidden;
  @apply text-white !important;
}
.btn-style-main:hover {
  @apply bg-Primary-2;
}
.btn-style-main:hover .icon::before {
  @apply translate-x-[125%];
}
.btn-style-main:hover .icon::after {
  @apply translate-x-0;
}
.btn-style-main .text {
  @apply flex items-end justify-center;
  @apply text-white capitalize text-sm font-semibold py-3 px-6 gap-3;
  @apply transition-all duration-300;
}
.btn-style-main::before {
  content: "";
  @apply absolute top-0 left-0 w-full h-full -z-1;
}
.btn-style-main .icon {
  @apply rem:w-[16px] rem:h-[20px] relative overflow-hidden flex-shrink-0;
}
.btn-style-main .icon::before {
  @apply translate-x-0;
}
.btn-style-main .icon::after {
  @apply -translate-x-[125%];
}
.btn-style-main .icon::before, .btn-style-main .icon::after {
  content: "\f061";
  @apply font-awesome text-base inline-block w-full h-full absolute-full font-light;
}

.btn-ctn.btn-primary-doc, .btn-ctn.btn-primary-video {
  @apply bg-Primary-1 text-white text-lg hover:bg-Primary-4;
}
.btn-ctn.btn-primary-doc .icon::before, .btn-ctn.btn-primary-video .icon::before {
  content: "\f15b";
  @apply font-awesome text-white font-black;
}
.btn-ctn.btn-primary-video .icon::before {
  content: "\f04b";
  @apply font-awesome text-white font-black;
}
.btn-ctn.btn-secondary {
  @apply transition-all duration-300 text-lg font-semibold text-Primary-1 border-solid border border-Primary-1;
}
.btn-ctn.btn-secondary:hover {
  @apply text-[#FFDE00] border-[#FFDE00];
}

.swiper-wrapper-global .btn-prev {
  background: red;
  background-image: url("../img/arrow-left.png");
  transform: translate(-50%, -50%);
  @apply absolute top-1/2 left-0 z-100 block;
}
.swiper-wrapper-global .btn-next {
  @apply bg-white absolute top-1/2 right-0;
}
.swiper-wrapper-global .btn-next::before {
  @apply content-[""] font-awesome;
}

.btn-swiper-style-1 .button-next,
.btn-swiper-style-1 .button-prev {
  border: 2px solid rgb(159, 161, 163);
  @apply bg-white cursor-pointer absolute z-100 text-xl flex-center gap-3 py-2 px-4 font-light;
}
.btn-swiper-style-1 .button-next {
  @apply top-1/2 right-0;
  @apply lg:translate-x-2/4 -translate-y-2/4;
}
.btn-swiper-style-1 .button-next::before {
  @apply content-[""] font-awesome text-[#9FA1A3];
}
.btn-swiper-style-1 .button-prev {
  @apply top-1/2 left-0;
  @apply lg:-translate-x-2/4 -translate-y-2/4;
}
.btn-swiper-style-1 .button-prev::before {
  @apply content-[""] font-awesome text-[#9FA1A3];
}

.btn-swiper-style-2 .button-next,
.btn-swiper-style-2 .button-prev {
  border: 2px solid rgb(159, 161, 163);
  @apply bg-white cursor-pointer absolute z-100 text-xl flex-center gap-3 py-2 px-4 font-light;
}
.btn-swiper-style-2 .button-next {
  @apply top-1/2 right-0;
  @apply lg:translate-x-2/4 -translate-y-2/4 xl:rem:right-[-9%];
}
.btn-swiper-style-2 .button-next::before {
  @apply content-[""] font-awesome text-[#9FA1A3];
}
.btn-swiper-style-2 .button-prev {
  @apply top-1/2 left-0;
  @apply lg:-translate-x-2/4 -translate-y-2/4 xl:rem:left-[-9%];
}
.btn-swiper-style-2 .button-prev::before {
  @apply content-[""] font-awesome text-[#9FA1A3];
}

.btn-swiper-style-3 {
  @apply relative flex gap-3 sm:pb-10 lg:rem:pb-40;
}
.btn-swiper-style-3 .button-next,
.btn-swiper-style-3 .button-prev {
  border: 2px solid rgb(159, 161, 163);
  @apply bg-white cursor-pointer  text-xl py-2 px-4 font-light;
}
.btn-swiper-style-3 .button-next::before {
  @apply content-[""] font-awesome text-[#9FA1A3];
}
.btn-swiper-style-3 .button-prev::before {
  @apply content-[""] font-awesome text-[#9FA1A3];
}
@media (max-width: 768px) {
  .btn-swiper-style-3 .button-next,
  .btn-swiper-style-3 .button-prev {
    @apply absolute rem:top-[200px];
  }
  .btn-swiper-style-3 .button-next {
    @apply -translate-x-2/4 -translate-y-2/4 rem:left-[55%] ml-4;
  }
  .btn-swiper-style-3 .button-prev {
    @apply -translate-x-2/4 -translate-y-2/4 rem:right-[47%];
  }
}

.btn-swiper-style-4 {
  @apply flex-center flex-row-reverse mt-5 xl:mt-0;
}
.btn-swiper-style-4 .button-next,
.btn-swiper-style-4 .button-prev {
  @apply rounded-full text-white bg-[#01A6F0] font-normal cursor-pointer;
  @apply flex-center gap-3 text-2xl font-thin rem:w-[64px] rem:h-[64px];
}
.btn-swiper-style-4 .button-next {
  @apply static ml-2 xl:absolute left-full top-1/2  xl:translate-x-2/4 z-100;
}
.btn-swiper-style-4 .button-next::before {
  @apply content-[""] font-awesome;
}
.btn-swiper-style-4 .button-prev {
  @apply static mr-2 xl:absolute right-full top-1/2  xl:-translate-x-2/4 z-100;
}
.btn-swiper-style-4 .button-prev::before {
  @apply content-[""] font-awesome;
}

.title-56 {
  @apply text-[28px] md:text-4xl lg:text-5xl xl:rem:text-[56px] font-bold uppercase leading-normal;
}

.title-64 {
  @apply text-4xl md:text-5xl lg:text-6xl xl:text-64 font-bold uppercase leading-normal;
}

.title-40 {
  @apply text-[32px] lg:text-5xl font-bold;
}

.title-48 {
  @apply text-4xl lg:text-6xl leading-tight font-bold;
}

.title-28 {
  @apply text-2xl lg:rem:text-[28px] font-bold leading-tight;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  html {
    scroll-padding-top: var(--header-height);
    scroll-behavior: smooth;
  }
  html, body {
    font-size: 17px;
  }
  @screen md {
    html, body {
      font-size: 15px;
    }
  }
  @screen xl {
    html, body {
      font-size: 1vw;
    }
  }
  .table-responsive {
    @apply overflow-auto;
  }
  @media (max-width: 767.98px) {
    .table-responsive table {
      width: 900px !important;
    }
  }
  .img-full img {
    @apply w-full h-full object-cover;
  }
  .img-ratio {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 100%;
  }
  .img-ratio img, .img-ratio iframe {
    @apply absolute top-0 left-0 w-full h-full object-cover transition-all duration-300;
  }
  .img-ratio-center {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-top: 100%;
    @apply flex-center;
  }
  .img-ratio-center img, .img-ratio-center iframe {
    @apply absolute top-1/2 left-1/2 w-auto h-auto object-contain transition-all duration-300 max-w-full max-h-full -translate-x-1/2 -translate-y-1/2;
  }
  .div-ratio {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 100%;
  }
  .div-ratio > div {
    @apply absolute top-0 left-0 w-full h-full transition-all duration-300;
  }
  body {
    @apply text-[#333333] text-base font-primary;
  }
  .row {
    @apply flex flex-wrap -mx-[7.5px] lg:-mx-5;
  }
  .col {
    @apply px-[7.5px] lg:px-5 w-full;
  }
  h1 {
    @apply text-3xl font-bold;
  }
  br {
    display: block;
    content: "";
    @apply mb-5;
  }
  h2 {
    @apply text-2xl font-bold;
  }
  h3 {
    @apply text-xl font-bold;
  }
  h4 {
    @apply text-lg font-bold;
  }
  img {
    @apply inline max-w-none;
  }
  a {
    @apply transition-all ease-linear duration-200;
  }
  p {
    @apply empty:hidden;
  }
  input {
    @apply outline-offset-0 shadow-none ring-0 !important;
  }
  .section {
    @apply py-12.5 lg:py-20;
  }
  .section-40 {
    @apply lg:py-10 py-6;
  }
  .margin-p p + p {
    @apply mt-5;
  }
  .overflow-scroll-1::-webkit-scrollbar {
    @apply w-[calc(6/1920*100rem)];
  }
  .overflow-scroll-1::-webkit-scrollbar-track {
    background-color: rgba(229, 229, 229, 0.2);
  }
  .overflow-scroll-1::-webkit-scrollbar-thumb {
    background-color: #f1f1f1;
  }
  body.disable-scroll {
    @apply overflow-hidden;
  }
  .format-content {
    @apply lg:text-[clamp(16px,0.9375vw,0.9375vw)] !important;
    @apply text-[16px]  text-justify;
  }
  .format-content img {
    @apply w-fit mx-auto max-w-full;
  }
  .format-content p {
    @apply mb-3;
  }
  .format-content ul {
    @apply list-disc pl-5 flex flex-col gap-3 mb-3;
  }
  .overlay-black {
    @apply relative z-2;
  }
  .overlay-black::before {
    content: "";
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.75) 100%);
    @apply absolute top-0 left-0 w-full h-full -z-1 pointer-events-none;
  }
  .mb-base {
    @apply lg:mb-10 mb-8;
  }
  .gap-base {
    @apply lg:gap-5 xl:gap-10 gap-5;
  }
  .p-base {
    @apply lg:p-10 md:p-5 p-[15px];
  }
  .py-base {
    @apply lg:py-10 py-5;
  }
  .pt-base {
    @apply lg:py-10 py-5;
  }
}
@layer components {
  .line-white {
    @apply w-full h-[1px] bg-white my-5;
  }
  .indicator-swipe {
    @apply z-30 bg-black/20 w-[calc(144/19.2*1rem)] h-14 rounded-full flex justify-between items-center px-3 pointer-events-none;
    @apply transition-all;
  }
  .indicator-swipe:not(.active) {
    @apply opacity-0;
  }
  .indicator-swipe svg {
    @apply stroke-white;
  }
  .indicator-swipe .hand {
    animation: moveSwipeGesture 2.25s infinite ease-in-out;
    @apply md:w-12 md:h-12 w-10 h-10;
  }
  .item-news {
    @apply grid lg:grid-cols-2 grid-cols-1;
  }
  .item-news.small .title-28 {
    @apply text-xl;
  }
  .item-news .wrap-img {
    @apply lg:pr-5;
  }
  .overlay-black {
    background-color: rgba(0, 0, 0, 0.02);
    @apply fixed top-0 left-0 w-full h-full z-[1000] pointer-events-none opacity-0 transition-all duration-300;
  }
  .invalid-feedback {
    @apply text-red-500 pt-2;
  }
  .pagination-wrapper ul {
    @apply flex items-center justify-center gap-5;
  }
  .pagination-wrapper ul li a {
    @apply flex-center w-12.5 h-12.5 border border-Primary-1 text-center hover:bg-Primary-1 hover:text-Primary-2 transition-all duration-300;
  }
  .pagination-wrapper ul li a.current {
    @apply bg-Primary-1 text-Primary-2;
  }
}
@keyframes moveSwipeGesture {
  0% {
    transform: translateX(-8px);
  }
  50% {
    transform: translateX(8px);
  }
  100% {
    transform: translateX(-8px);
  }
}
@layer utilities {
  .title-56 {
    @apply text-[28px] md:text-4xl lg:text-5xl xl:rem:text-[56px] font-bold uppercase leading-normal;
  }
  .title-64 {
    @apply text-4xl md:text-5xl lg:text-6xl xl:text-64 font-bold uppercase leading-normal;
  }
  .title-40 {
    @apply text-[32px] lg:text-5xl font-bold;
  }
  .title-48 {
    @apply text-4xl lg:text-6xl leading-tight font-bold;
  }
  .title-28 {
    @apply text-2xl lg:rem:text-[28px] font-bold leading-tight;
  }
  .section-py {
    @apply py-10 lg:py-15 xl:py-20;
  }
  .no-gutters {
    @apply mx-0;
  }
  .no-gutters > * {
    @apply px-0;
  }
  .lozad {
    opacity: 0;
    filter: blur(10px);
    transition: 0.3s all ease-in-out;
  }
  .lozad[data-loaded=true] {
    opacity: 1;
    filter: blur(0);
  }
  .container-fullpage {
    @apply w-full lg:px-25 px-[15px];
  }
  .gradient-primary {
    background: linear-gradient(180deg, #01A6F0 0%, #01699E 100%);
  }
  .scroll-primary::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  .scroll-primary::-webkit-scrollbar-track {
    @apply bg-neutral-300;
  }
  .scroll-primary::-webkit-scrollbar-thumb {
    @apply bg-Primary-2;
  }
  .form-message .alert {
    @apply w-full p-2 border-2;
  }
  .form-message .alert.alert-danger {
    @apply border-red-500;
  }
  .form-message .alert.alert-success {
    @apply border-green-500;
  }
  .desktop-only {
    @apply hidden lg:block;
  }
  .mobile-only {
    @apply block lg:hidden;
  }
  .img-contain img {
    @apply object-contain;
  }
}
.global-breadcrumb {
  @apply bg-[#f1f1f1] border-b border-white;
}
.global-breadcrumb p {
  @apply h-[calc(48/1920*100rem)] flex items-center overflow-auto;
}
.global-breadcrumb p a:first-child {
  @apply text-0;
}
.global-breadcrumb p a:first-child::before {
  content: "\f015";
  @apply font-awesome text-sm;
}
.global-breadcrumb p a, .global-breadcrumb p span {
  @apply text-sm text-[#333333] whitespace-nowrap;
}
.global-breadcrumb p span.separator {
  @apply text-0 inline-flex;
}
.global-breadcrumb p span.separator::before {
  content: "\f054";
  @apply font-awesome font-normal text-sm mx-2;
}

.banner-global::before {
  background: linear-gradient(180deg, transparent 32.57%, rgba(0, 0, 0, 0.75));
  @apply content-[""] absolute-full h-full z-2;
}
.banner-global .breadcrumb-wrapper {
  @apply absolute bottom-[30px] lg:rem:bottom-[35px] left-0 w-full z-10;
}
.banner-global .breadcrumb-wrapper p {
  @apply h-[calc(48/1920*100rem)] flex items-center overflow-auto;
}
.banner-global .breadcrumb-wrapper p a:first-child {
  @apply text-0 flex leading-[1] !important;
}
.banner-global .breadcrumb-wrapper p a:first-child::before {
  content: "\f015";
  @apply font-awesome text-lg font-light;
}
.banner-global .breadcrumb-wrapper p a, .banner-global .breadcrumb-wrapper p span {
  @apply text-base text-white whitespace-nowrap;
}
.banner-global .breadcrumb-wrapper p span.separator {
  @apply text-0 inline-flex mx-3 leading-[1] !important;
}
.banner-global .breadcrumb-wrapper p span.separator::before {
  content: "\f054";
  @apply font-awesome font-light text-base mx-1;
}
.banner-global video {
  @apply rem:h-[720px];
}

footer .footer-desc {
  @apply text-lg;
}
footer .footer-slogan .text-stroke {
  -webkit-background-clip: text !important;
  font-weight: 700;
  letter-spacing: -0.2rem;
  text-transform: uppercase;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: white;
  -webkit-text-stroke-width: 0.1041666667rem;
}
footer .footer-slogan .title {
  letter-spacing: -0.075rem;
  @apply md:text-[46px] lg:rem:text-[72px] font-bold leading-none text-[30px];
}
footer .list-footer li a {
  position: relative;
  transition: all 0.3s ease;
  background-image: linear-gradient(180deg, transparent 95%, #ffde00 0);
  background-repeat: no-repeat;
  background-size: 0 100%;
  @apply hover:text-Primary-2 relative py-2 text-lg;
}
footer .list-footer li a:hover {
  background-size: 100% 100%;
}
footer .footer-menu .col-footer .title-footer {
  @apply md:px-5 lg:py-6 py-2;
}
footer .footer-menu .col-footer .wrap-footer-content {
  @apply md:px-5 lg:py-6 py-2;
}
footer .footer-social {
  @apply flex items-center gap-2 mt-6;
}
footer .footer-social li a {
  @apply bg-white rounded-full w-10 h-10 flex-center transition-all duration-300 hover:bg-Primary-1;
}
footer .footer-social li a i {
  @apply text-Primary-7;
}
footer .wrap-footer-form {
  @apply flex border border-white;
}
footer .wrap-footer-form .wpcf7-form-control-wrap {
  @apply flex-1;
}
footer .wrap-footer-form input {
  @apply h-10 px-5 bg-white/10 placeholder:text-white/80 text-white outline-none min-w-[1px] w-full text-sm;
}
footer .wrap-footer-form button {
  @apply text-0  bg-white/10 w-10 flex-center flex-shrink-0;
}
footer .wrap-footer-form button::before {
  content: "\f178";
  @apply font-awesome  text-Primary-2 text-xl font-light;
}
footer .footer-copyright img {
  @apply rem:h-[160px];
}
footer .footer-copyright .footer-list-link li + li::before {
  content: "";
  @apply w-[1px] h-3 bg-white/20 inline-block mx-3;
}

.tool-hotline {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation: pulse-red 2s infinite;
  @apply fixed bottom-5 right-5 z-50 w-[55px] h-[55px] bg-Primary-1 rounded-full flex-center text-white;
  @apply text-2xl md:hidden;
}
.tool-hotline i {
  animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
}

@keyframes coccoc-alo-circle-img-anim {
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}
@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 183, 241, 0.6);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
header {
  @apply h-20 bg-Primary-6 sticky lg:fixed top-0 left-0 right-0 z-[1000] transition-all duration-300;
}
header nav {
  @apply h-full text-white flex justify-between items-center lg:px-25 px-[15px];
}
header nav .header-left {
  @apply flex items-center gap-3 relative z-2;
}
header nav .header-logo {
  @apply left-1/2 -translate-x-1/2 absolute;
}
header nav .header-logo a {
  @apply relative pt-5 block;
}
header nav .header-logo a::before {
  content: "";
  border-radius: 50%;
  @apply w-[15.6875rem] h-[11.041667rem] md:rem:w-[378px] md:rem:h-[212px] bg-Primary-6 absolute top-0 left-1/2 -translate-x-1/2 -z-1 -translate-y-[43%] pointer-events-none;
}
header nav .header-logo a img {
  @apply rem:w-[144px];
}
header nav .header-hotline {
  @apply rounded-full h-10 items-center gap-2 px-4 border border-Primary-2 text-Primary-2 hidden md:flex;
}
header nav .header-hambuger {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5) inset;
  @apply w-15 h-15 flex-center bg-white rounded-5;
}
header nav .header-hambuger .hambuger {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5);
  background: linear-gradient(180deg, #01A6F0 0%, #01A6F0 100%);
  @apply rounded-xl p-2 pointer-events-none;
  @apply w-10 h-10 flex flex-col gap-2;
}
header nav .header-hambuger .hambuger span {
  @apply h-[2px] w-6 bg-white rounded-full;
}
header .header-hambuger {
  @apply transition-all duration-300;
}
header .header-hambuger:hover {
  @apply bg-Primary-2;
}
header .header-hambuger:hover .hambuger {
  background: linear-gradient(180deg, #01A6F0 0%, #01699E 100%);
}
header .header-hambuger:hover .hambuger span:nth-child(1) {
  @apply w-[60%];
}
header .header-hambuger:hover .hambuger span:nth-child(2) {
  @apply w-[50%];
}
header .header-hambuger:hover .hambuger span:nth-child(3) {
  @apply w-[40%];
}
header .header-hambuger:hover .hambuger::before {
  @apply -translate-x-0;
}
header .header-hambuger .hambuger {
  @apply relative overflow-hidden z-2;
}
header .header-hambuger .hambuger::before {
  content: "";
  @apply w-[70%] h-full bg-Primary-4 absolute top-0 left-0 -z-1 -translate-x-full transition-all duration-300;
}

.header-language {
  @apply relative;
}
.header-language:hover ul {
  @apply opacity-100 pointer-events-auto;
}
.header-language > ul > li > a {
  @apply text-sm uppercase text-white font-medium;
}
.header-language > ul > li > a::after {
  content: "\f078";
  @apply font-awesome font-light text-white ml-1 text-xs;
}
.header-language > ul > ul {
  @apply transition-all duration-300;
  @apply opacity-0 absolute top-full left-1/2 -translate-x-1/2 pointer-events-none;
}
.header-language > ul > ul > li > a {
  @apply flex-center bg-Primary-2 px-2 py-0.25 text-sm text-black hover:bg-Primary-2/80;
}

.header-search {
  @apply flex-center w-10 h-10 border-Primary-2 rounded-full border cursor-pointer;
}
.header-search i {
  @apply text-Primary-2 pointer-events-none;
}

.header-sidebar-menu {
  @apply fixed right-0 top-0 h-screen lg:rem:w-[825px] w-screen;
  @apply px-[15px] lg:px-20 py-10 text-white z-[1001] flex flex-col items-end gap-5 lg:gap-10 transition-all duration-300;
  @apply translate-x-full;
  @apply gradient-primary;
}
.header-sidebar-menu::before {
  content: "";
  background-image: url(../img/header-bg.png);
  background-position: left bottom;
  background-size: 29.4791666667rem 31.09375rem;
  background-repeat: no-repeat;
  @apply absolute top-0 left-0 w-full h-full pointer-events-none;
}
.header-sidebar-menu .close {
  @apply text-Primary-2 text-3xl lg:text-32 cursor-pointer;
}
.header-sidebar-menu .header-menu ul li[class*=current] > a {
  @apply text-Primary-2;
}
.header-sidebar-menu .header-menu ul li[class*=current] > a::before {
  @apply w-full bg-Primary-2;
}
.header-sidebar-menu .header-menu ul li a {
  @apply relative;
}
.header-sidebar-menu .header-menu ul li a::before {
  content: "";
  @apply w-[0px] h-[1px] bg-white absolute top-full mt-1 left-0 transition-all duration-300;
}
.header-sidebar-menu .header-menu ul li a:hover {
  @apply opacity-100;
}
.header-sidebar-menu .header-menu ul li a:hover::before {
  @apply w-full right-0 left-auto;
}
.header-sidebar-menu .header-menu > ul {
  @apply flex flex-col lg:gap-8 gap-3;
}
.header-sidebar-menu .header-menu > ul > li {
  @apply text-right;
}
.header-sidebar-menu .header-menu > ul > li > a {
  @apply text-3xl lg:rem:text-[40px] inline-block text-right;
}
.header-sidebar-menu .header-menu > ul > li > ul {
  @apply flex-col mt-10 hidden;
}
.header-sidebar-menu .header-menu > ul > li > ul > li {
  @apply text-right mb-5 last:mb-0;
}
.header-sidebar-menu .header-menu > ul > li > ul > li > a {
  @apply rem:text-[28px] text-right opacity-50;
}
.header-sidebar-menu .header-social ul {
  @apply flex items-center gap-2;
}
.header-sidebar-menu .header-social ul li a {
  @apply flex-center rounded-full w-10 h-10 text-Primary-3 bg-white hover:bg-Primary-2 transition-all duration-300;
}

body.isOpenMenu .header-sidebar-menu {
  @apply translate-x-0;
}
body.isOpenMenu .overlay-black {
  @apply opacity-100 pointer-events-auto;
}

body.hidden-fullpage header {
  @apply -translate-y-[200%];
}
body.hidden-fullpage .header-toggle-fullpage {
  @apply translate-y-0;
}

.header-toggle-fullpage {
  @apply fixed right-5 top-0 flex flex-col items-center z-[999];
  @apply -translate-y-[100%] transition-all duration-300;
}
.header-toggle-fullpage::before {
  content: "";
  @apply w-[1px] h-[50px] bg-Primary-2 inline-block;
}
.header-toggle-fullpage .tag {
  writing-mode: vertical-rl;
  @apply border-Primary-2 border-2 bg-Primary-6 px-2 py-2 text-Primary-2 text-sm cursor-pointer;
}

.header-hambuger {
  @apply flex flex-col gap-2 ml-7 md:ml-11 lg:ml-11 xl:ml-[calc(58/19.2*1rem)] cursor-pointer z-10 relative lg:hidden;
}
.header-hambuger span {
  @apply transition-all duration-300 pointer-events-none;
  @apply w-7 h-1 bg-white;
}
.header-hambuger span:nth-child(1) {
  transform-origin: center left;
}
.header-hambuger span:nth-child(2) {
  @apply opacity-100;
}
.header-hambuger span:nth-child(3) {
  transform-origin: center left;
}
.header-hambuger #pulseMe {
  transform-origin: center center;
  @apply pointer-events-none;
  @apply left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[38px] h-[38px] absolute;
  @apply bg-[#1f7a42] -z-1;
}
.header-hambuger #pulseMe .bar {
  box-shadow: 0 0 0 #ffffff, 0 0 1px #ffffff, 0 0 1px #ffffff, 0 0 4px #ffffff;
  @apply absolute bg-white;
}
.header-hambuger #pulseMe .bar.left {
  animation: leftBar 2s linear infinite;
  @apply w-[1px];
}
.header-hambuger #pulseMe .bar.top {
  animation: topBar 2s linear infinite;
  @apply h-[1px] w-0;
}
.header-hambuger #pulseMe .bar.right {
  animation: rightBar 2s linear infinite;
  @apply w-[1px];
}
.header-hambuger #pulseMe .bar.bottom {
  animation: bottomBar 2s linear infinite;
  @apply h-[1px];
}

@keyframes leftBar {
  0% {
    height: 0;
    left: 0;
    top: 100%;
  }
  20% {
    height: 100%;
    left: 0;
    top: 0;
  }
  40% {
    height: 0;
    left: 0;
    top: 0;
  }
}
@keyframes topBar {
  0%, 20% {
    left: 0;
    top: 0;
    width: 0;
  }
  40% {
    left: 0;
    top: 0;
    width: 100%;
  }
  60% {
    left: calc(100% - 1px);
    top: 0;
    width: 0;
  }
}
@keyframes rightBar {
  0%, 40% {
    height: 0;
    left: calc(100% - 1px);
    top: 0;
  }
  60% {
    height: 100%;
    left: calc(100% - 1px);
    top: 0;
  }
  80% {
    height: 0;
    left: calc(100% - 1px);
    top: 100%;
  }
}
@keyframes bottomBar {
  0%, 60% {
    left: 100%;
    top: 100%;
    width: 0;
  }
  80% {
    left: 0;
    top: 100%;
    width: 100%;
  }
  100% {
    left: 0;
    top: 100%;
    width: 0;
  }
}
.header-search-form {
  visibility: hidden;
  @apply fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 backdrop-blur-md z-[1000] flex justify-center items-center transition-all duration-300 opacity-0  pointer-events-none;
}
.header-search-form.active {
  @apply opacity-100 visible pointer-events-auto;
}
.header-search-form .wrap-form-search-product {
  max-width: 50rem;
  margin: 0 auto;
  @apply w-full;
}
.header-search-form .wrap-form-search-product .productsearchbox {
  @apply w-full flex relative flex-wrap;
}
.header-search-form .wrap-form-search-product .productsearchbox [class*=Module] {
  width: 100% !important;
}
.header-search-form .wrap-form-search-product .productsearchbox input {
  @apply flex-1 h-12.5 bg-white text-base px-7.5 outline-0 border-0 !important;
}
.header-search-form .wrap-form-search-product .productsearchbox button {
  @apply w-12.5 flex items-center justify-center  outline-0 border-0 border-l border-[#E0E0E0] bg-Primary-6 text-Primary-2;
}
.header-search-form .wrap-form-search-product .productsearchbox button::before {
  content: "\f002";
  @apply font-awesome text-2xl font-light;
}

.section-career-1 {
  @apply relative z-2;
  @apply rem:min-h-[680px] flex-center flex-col;
}
.section-career-1::before {
  content: "";
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 14.26%, #F4F4F4 62.56%);
  @apply absolute inset-0 w-full h-full -z-1;
}

.section-career-2 .item-box {
  @apply border border-neutral-200 border-solid;
}
.section-career-2 .item-box .wrapper-box {
  @apply transition-all duration-200 cursor-pointer py-10 px-5;
}
.section-career-2 .item-box .wrapper-box .image svg {
  @apply fill-[#989898];
}
.section-career-2 .item-box .wrapper-box:hover {
  @apply bg-Primary-1;
}
.section-career-2 .item-box .wrapper-box:hover .image svg path {
  @apply fill-white;
}
.section-career-2 .item-box .wrapper-box:hover .title {
  @apply text-Primary-2;
}
.section-career-2 .item-box .wrapper-box:hover .desc {
  @apply text-white;
}

.section-career-3 .content-table table {
  @apply w-full;
}
.section-career-3 .content-table table th {
  @apply p-2 text-white uppercase border-solid border border-b-[#CEDBE6] bg-Primary-1;
  @apply text-xl font-bold px-5;
}
.section-career-3 .content-table table tr {
  @apply relative;
}
.section-career-3 .content-table table tr:hover td {
  @apply text-Primary-1;
}
.section-career-3 .content-table table tr td {
  @apply text-lg text-neutral-950 py-3 px-5 text-center border-solid border border-b-[#CEDBE6];
  @apply transition-all duration-200;
}
.section-career-3 .content-table table tr td a {
  @apply inline-block;
}
.section-career-3 .content-table table tr td:nth-child(1) {
  @apply text-black font-bold;
}
.section-career-3 .content-table table tr td:nth-child(2) {
  @apply text-left whitespace-nowrap lg:whitespace-normal;
}
.section-career-3 .content-table table tr td:nth-child(3) {
  @apply whitespace-nowrap;
}
.section-career-3 .content-table table tr td:last-child {
  @apply rem:w-[220px];
}
.section-career-3 .content-table table tr:nth-child(even) {
  @apply bg-[#f6f6f6];
}

.section-about-1 {
  transition: background 0s ease-in-out;
  @apply overflow-hidden;
}
.section-about-1 .item-col-left .format-content p {
  @apply break-inside-avoid;
}
.section-about-1 .item-col-right, .section-about-1 .item-col-left {
  @apply xl:text-[1vw] md:text-[2vw] text-[1vw];
}
.section-about-1 .item-col-right .wrap-items, .section-about-1 .item-col-left .wrap-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  aspect-ratio: 1;
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  @apply w-full xl:h-full;
}
.section-about-1 .item-col-right .wrap-items .item, .section-about-1 .item-col-left .wrap-items .item {
  @apply h-full;
}
.section-about-1 .item-col-right .wrap-items .item:nth-child(1), .section-about-1 .item-col-left .wrap-items .item:nth-child(1) {
  grid-area: 1/1/3/3;
}
.section-about-1 .item-col-right .wrap-items .item:nth-child(2), .section-about-1 .item-col-left .wrap-items .item:nth-child(2) {
  grid-area: 1/3/2/4;
}
.section-about-1 .item-col-right .wrap-items .item:nth-child(3), .section-about-1 .item-col-left .wrap-items .item:nth-child(3) {
  grid-area: 2/3/3/4;
}
.section-about-1 .item-col-right .wrap-items .item:nth-child(4), .section-about-1 .item-col-left .wrap-items .item:nth-child(4) {
  grid-area: 3/1/4/3;
}
.section-about-1 .item-col-right .wrap-items .item:nth-child(5), .section-about-1 .item-col-left .wrap-items .item:nth-child(5) {
  grid-area: 3/3/4/4;
}
.section-about-1 .item-col-right .item-edge-dragon::before, .section-about-1 .item-col-left .item-edge-dragon::before {
  content: "";
  background-image: url("../img/home-about-dragon.png");
  background-size: 100% 100%;
  @apply w-[400px] h-[300px] md:em:w-[962px] md:em:h-[581px];
  @apply opacity-60;
  @apply absolute right-0 bottom-0;
  @apply inline-block;
}
@media (max-width: 767.98px) {
  .section-about-1 .item-col-right .item-edge-dragon::before, .section-about-1 .item-col-left .item-edge-dragon::before {
    width: 65%;
    background-position: bottom right;
    height: 92%;
    content: "";
    background-repeat: no-repeat;
    transform: translateY(36%);
  }
}
@screen md {
  .section-about-1 .item-col-right .item-edge-dragon::before, .section-about-1 .item-col-left .item-edge-dragon::before {
    transform: translateY(40%);
  }
}

.section-about-2 {
  min-height: 39.1875rem;
  @apply relative;
}
@screen lg {
  .section-about-2 .block-image {
    @apply lg:w-5/12 absolute top-0 right-0 h-full;
  }
}

.section-about-4 {
  @apply relative;
}
.section-about-4 .image-wrapper {
  @apply relative rem:h-[780px] overflow-hidden;
}
.section-about-4 .image-wrapper .img-item {
  @apply relative overflow-hidden;
}
.section-about-4 .image-wrapper .img-item img {
  @apply w-full h-full object-cover duration-500 transition-all overflow-hidden;
}
.section-about-4 .image-wrapper .img-item:hover img {
  @apply scale-110;
}
.section-about-4 .image-wrapper .img-item:nth-child(1) {
  @apply absolute rem:top-[0] rem:left-[80px] rem:w-[240px] rem:h-[240px];
}
.section-about-4 .image-wrapper .img-item:nth-child(2) {
  @apply absolute rem:top-[75px] rem:right-[70px] rem:w-[320px] rem:h-[320px] z-1;
}
.section-about-4 .image-wrapper .img-item:nth-child(3) {
  @apply absolute rem:top-[280px] rem:left-[5px] rem:w-[480px] rem:h-[480px];
}

.section-about-5 h2 br {
  @apply mb-0;
}

.section-about-6 .wrapper-year .swiper-slide {
  transition: opacity 0.3s ease-in-out;
  @apply opacity-20 pl-2;
}
.section-about-6 .wrapper-year .swiper-slide .content {
  @apply relative flex flex-col gap-5 border-l border-b-white;
}
.section-about-6 .wrapper-year .swiper-slide .content::before {
  @apply content-[""] absolute rem:top-[110px] rem:left-[-3px] bg-Primary-2 rem:w-[6px] rem:h-[6px];
}
.section-about-6 .wrapper-year .swiper-slide .content .year {
  @apply title-56 font-bold text-Primary-2;
}
.section-about-6 .wrapper-year .swiper-slide .content .caption {
  @apply pl-4 text-xl font-bold text-white;
}
.section-about-6 .wrapper-year .swiper-slide .content .desc {
  @apply pl-4 text-xl font-normal text-white;
}
.section-about-6 .wrapper-year .swiper-slide.swiper-slide-active {
  opacity: 1;
}
.section-about-6 .wrapper-year .wrapper-pagination-year {
  @apply pt-10;
}
.section-about-6 .wrapper-year .wrapper-pagination-year ul {
  @apply overflow-hidden overflow-x-auto flex items-center justify-center gap-20;
}
.section-about-6 .wrapper-year .wrapper-pagination-year ul li span {
  @apply cursor-pointer rem:text-[28px] leading-1.3 text-white font-normal;
}
.section-about-6 .wrapper-year .wrapper-pagination-year ul li.active span {
  @apply text-Primary-2;
}
.section-about-6 .wrapper-year .indicator-swipe {
  @apply bg-Primary-1 right-[115px] lg:right-[20%] rem:bottom-[20%] absolute -translate-y-1/2;
}

.section-about-7 .list-tab {
  @apply mt-8 lg:mt-0 max-w-full;
}
.section-about-7 .list-tab ul {
  @apply flex gap-3 lg:gap-5 items-center overflow-auto;
}
.section-about-7 .list-tab ul li {
  @apply first:ml-auto last:mr-auto;
}
.section-about-7 .list-tab ul li.active a {
  @apply bg-Primary-1 text-Primary-2;
}
.section-about-7 .list-tab ul li a {
  @apply text-Primary-1 border-solid border border-Primary-1;
  @apply font-medium text-lg py-3 px-5 flex-center whitespace-nowrap;
}
.section-about-7 .list-tab ul li a.active, .section-about-7 .list-tab ul li a:hover {
  @apply bg-Primary-1 text-Primary-2 transition-all duration-300;
}
.section-about-7 .home-cols-1 .swiper {
  @apply shadow-[4px_4px_32px_16px_rgba(0,0,0,0.08)];
}
.section-about-7 .wrapper-content .content {
  @apply rem:min-h-[580px];
}
.section-about-7 .wrapper-content .content .desc {
  @apply rem:max-h-[300px] overflow-auto;
}
.section-about-7 .wrapper-content .content .desc::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.section-about-7 .wrapper-content .content .desc::-webkit-scrollbar-track {
  @apply bg-neutral-300;
}
.section-about-7 .wrapper-content .content .desc::-webkit-scrollbar-thumb {
  @apply bg-Primary-2;
}
.section-about-7 .wrapper-brand .swiper-wrapper {
  transition-timing-function: linear;
}
.section-about-7 .wrapper-brand.slide-center .home-cols-9 .swiper-wrapper {
  @apply justify-center flex gap-5;
}
.section-about-7 .wrapper-brand.slide-center .home-cols-9 .swiper-slide {
  @apply rem:max-w-[200px];
}

.section-contact-1 .list-social ul {
  @apply flex gap-2 items-center;
}
.section-contact-1 .list-social ul li {
  background: linear-gradient(180deg, #01A6F0 0%, #01699E 100%);
  @apply rounded-full rem:w-[40px] rem:h-[40px] flex-center gap-3 p-3;
}
.section-contact-1 .list-social ul li i {
  @apply text-Primary-2;
}
.section-contact-1 .list-social ul li img {
  @apply flex-center;
}

.section-contact-2 .content {
  @apply pr-5 rem:max-h-[400px] lg:rem:max-h-[820px] overflow-y-scroll;
}
.section-contact-2 .content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
  @apply bg-neutral-100 rounded-2.5;
}
.section-contact-2 .content::-webkit-scrollbar {
  @apply bg-neutral-100 rem:w-[8px];
}
.section-contact-2 .content::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
  @apply bg-Primary-1 rounded-2.5;
}
.section-contact-2 .content .box-item {
  @apply transition-all duration-300 cursor-pointer flex flex-col gap-2 bg-neutral-50 p-6;
}
.section-contact-2 .content .box-item.active {
  @apply bg-Primary-1;
}
.section-contact-2 .content .box-item.active .list-address .item-address .icon i {
  @apply text-Primary-2;
}
.section-contact-2 .content .box-item.active .list-address .item-address a {
  @apply text-white;
}
.section-contact-2 .content .box-item.active .title {
  @apply text-white;
}
.section-contact-2 .content .box-item .title {
  @apply text-Primary-1;
}
.section-contact-2 .content .list-address {
  @apply flex flex-col gap-2;
}
.section-contact-2 .content .list-address .item-address {
  @apply flex items-start gap-2;
}
.section-contact-2 .content .list-address .item-address .icon {
  @apply text-Primary-1;
}
.section-contact-2 .content .list-address .item-address .icon i {
  @apply font-normal;
}
.section-contact-2 .content .list-address .item-address a {
  @apply text-lg font-medium text-neutral-950;
}
.section-contact-2 .block-map {
  @apply hidden;
}
.section-contact-2 .block-map iframe {
  @apply w-full rem:h-[500px] lg:rem:h-[820px];
}
.section-contact-2 .block-map.active {
  @apply block;
}

.popup-modal {
  @apply bg-white rem:max-w-[900px] w-full p-10 lg:p-20;
}
.popup-modal .carousel__button.is-close {
  background: linear-gradient(180deg, #01A6F0 0%, #01699E 100%);
  @apply cursor-pointer p-3 rem:w-[40px] flex-center gap-2 top-0 rounded-none;
}
.popup-modal .popup-modal-wrapper form {
  @apply grid md:grid-cols-2 lg:gap-6 gap-2;
}
.popup-modal .popup-modal-wrapper label {
  @apply hidden;
}
.popup-modal .popup-modal-wrapper .f-message {
  @apply md:col-span-2;
}
.popup-modal .popup-modal-wrapper input {
  @apply p-3 outline-none w-full border-solid border border-[#DCDCDC];
}
.popup-modal .popup-modal-wrapper textarea {
  @apply p-5 w-full outline-none border-solid border border-[#DCDCDC];
}

@media (max-width: 1279.98px) {
  .popup-modal {
    @apply max-w-[95vw];
  }
}
#gva-overlay {
  background: rgba(0, 0, 0, 0.6);
  @apply fixed w-full h-full top-0 left-0 z-1 hidden cursor-pointer;
}

.section-nav ul {
  @apply flex-between gap-6 lg:gap-0 overflow-hidden overflow-x-auto;
}
.section-nav ul li {
  @apply relative;
}
.section-nav ul li.active a {
  @apply text-white;
}
.section-nav ul li.active a::before {
  @apply w-full;
}
.section-nav ul li:not(:last-child)::after {
  @apply content-[""] absolute top-1/2 rem:right-[-15px] lg:rem:right-[-70px] translate-x-[-50%] translate-y-[-50%] bg-white block z-999 rem:w-[3px] rem:h-[20px];
}
.section-nav ul li a {
  @apply whitespace-nowrap relative py-6 uppercase text-base font-bold text-white block;
}
.section-nav ul li a::before {
  @apply content-[""] absolute rem:bottom-[-2px] left-0 w-0 h-[4px] bg-white transition-all duration-300 z-999;
}
.section-nav ul li a:hover::before {
  @apply w-full;
}

.section-detail-pro-1 .wrapper-info .item-left h4,
.section-detail-pro-1 .wrapper-info .item-right h4 {
  @apply pb-5 uppercase text-xl font-bold text-neutral-950;
}
.section-detail-pro-1 .wrapper-info .item-left ul,
.section-detail-pro-1 .wrapper-info .item-right ul {
  @apply pl-0 !important;
}
.section-detail-pro-1 .wrapper-info .item-left ul,
.section-detail-pro-1 .wrapper-info .item-right ul {
  @apply flex flex-col gap-3;
}
.section-detail-pro-1 .wrapper-info .item-left ul li,
.section-detail-pro-1 .wrapper-info .item-right ul li {
  @apply pb-3 border-b border-neutral-200 w-full flex md:items-center flex-col md:flex-row;
}
.section-detail-pro-1 .wrapper-info .item-left ul li:last-child,
.section-detail-pro-1 .wrapper-info .item-right ul li:last-child {
  @apply border-none;
}
.section-detail-pro-1 .wrapper-info .item-left ul li span,
.section-detail-pro-1 .wrapper-info .item-right ul li span {
  @apply font-bold rem:min-w-[120px];
}
.section-detail-pro-1 .wrapper-info .item-left ul li p,
.section-detail-pro-1 .wrapper-info .item-right ul li p {
  @apply md:pl-2 pl-0;
}
.section-detail-pro-1 .wrapper-action .list-tutorial .btn-ctn {
  @apply my-4 sm:my-0 cursor-pointer gap-2 py-3 px-6 flex-center;
}
.section-detail-pro-1 .wrapper-action .list-social ul {
  @apply flex items-center gap-4 flex-wrap;
}
.section-detail-pro-1 .wrapper-action .list-social ul li {
  @apply p-3;
}
.section-detail-pro-1 .wrapper-action .list-social ul li a {
  @apply text-lg font-semibold text-white flex gap-2 flex-row-reverse items-center;
}
.section-detail-pro-1 .wrapper-action .list-social ul li i {
  @apply text-xl;
}
.section-detail-pro-1 .wrapper-action .list-social ul li:nth-child(1) {
  @apply bg-[#3B5696];
}
.section-detail-pro-1 .wrapper-action .list-social ul li:nth-child(2) {
  @apply bg-[#F40D1A];
}
.section-detail-pro-1 .sidebar-info {
  @apply h-fit shadow-[4px_4px_24px_0px_rgba(0,0,0,0.08)] flex flex-col gap-4;
}
.section-detail-pro-1 .sidebar-info .info-item {
  @apply border-b border-b-neutral-200;
}
.section-detail-pro-1 .sidebar-info .info-item:last-child {
  @apply border-none;
}
.section-detail-pro-1 .sidebar-info .info-item .ctn {
  @apply flex items-center gap-2;
}
.section-detail-pro-1 .sidebar-info .info-item .ctn i {
  @apply text-xl font-normal text-neutral-950;
}
.section-detail-pro-1 .sidebar-info .info-item .ctn span {
  @apply uppercase text-base font-normal text-neutral-950;
}
.section-detail-pro-1 .sidebar-info .info-item .counter {
  @apply text-28 font-bold text-[#009FDC];
}

.section-detail-pro-3 {
  min-height: 40rem;
  @apply relative;
}
@screen lg {
  .section-detail-pro-3 .block-map {
    @apply lg:w-5/12 absolute top-0 left-0 h-full;
  }
}
.section-detail-pro-3 .box-item {
  @apply px-0 p-5 lg:px-5 flex-center flex-col border-l-0 lg:border-l-3 border-b-neutral-300;
}
.section-detail-pro-3 .box-item:first-child {
  @apply pl-0 border-none;
}
.section-detail-pro-3 .box-item:last-child {
  @apply pr-0;
}
.section-detail-pro-3 .block-map iframe {
  @apply w-full h-full;
}

.section-detail-pro-4 .tab-feature ul {
  @apply flex-center gap-6 overflow-hidden overflow-x-auto;
}
.section-detail-pro-4 .tab-feature ul li {
  @apply relative pb-1;
}
.section-detail-pro-4 .tab-feature ul li:not(:last-child)::after {
  @apply content-[""] absolute top-1/2 rem:right-[-15px] lg:rem:right-[-12px] translate-x-[-50%] translate-y-[-50%]  bg-neutral-400 z-999 rem:w-[2px] rem:h-[20px];
}
.section-detail-pro-4 .tab-feature ul li a {
  @apply whitespace-nowrap cursor-pointer text-lg font-semibold text-neutral-500;
}
.section-detail-pro-4 .tab-feature ul li a::before {
  @apply content-[""] absolute bottom-0 left-0 w-0 h-[2px] bg-[#009FDC] transition-all duration-300 z-999;
}
.section-detail-pro-4 .tab-feature ul li a:hover {
  @apply text-[#009FDC];
}
.section-detail-pro-4 .tab-feature ul li a.active {
  @apply text-[#009FDC];
}
.section-detail-pro-4 .tab-feature ul li a.active::before {
  @apply w-full;
}
.section-detail-pro-4 .tab-feature ul li a:hover::before {
  @apply w-full;
}
.section-detail-pro-4 .tab-content {
  @apply hidden;
}
.section-detail-pro-4 .tab-content.active {
  @apply block;
}
.section-detail-pro-4 .tab-content.active .home-cols-feature .swiper-slide {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(6px);
  @apply backdrop-blur-[6px] transition-opacity duration-[0.3s] ease-[ease];
  @apply opacity-60 overflow-hidden;
}
.section-detail-pro-4 .tab-content.active .home-cols-feature .swiper-slide::before {
  backdrop-filter: blur(6px);
  background: rgba(255, 255, 255, 0.8);
  @apply opacity-80 content-[""] absolute-full h-full;
}
.section-detail-pro-4 .tab-content.active .home-cols-feature .swiper-slide .image {
  @apply w-full;
}
.section-detail-pro-4 .tab-content.active .home-cols-feature .swiper-slide-active {
  @apply opacity-100 duration-[0.3s] ease-[ease];
}
.section-detail-pro-4 .tab-content.active .home-cols-feature .swiper-slide:not(.swiper-slide-active)::before {
  @apply block;
}
.section-detail-pro-4 .tab-content.active .home-cols-feature .swiper-slide.swiper-slide-active::before {
  @apply hidden;
}

.section-detail-pro-5 .slide-main {
  @apply xl:rem:pr-[45px];
}
.section-detail-pro-5 .wrap-button-slide .btn-prev {
  @apply left-0 right-[auto] -translate-x-1/2;
}
.section-detail-pro-5 .wrap-button-slide .btn-next {
  @apply right-0 left-[auto] translate-x-1/2;
}
.section-detail-pro-5 .slide-thumb .swiper-slide .img {
  @apply border border-transparent;
}
.section-detail-pro-5 .slide-thumb .swiper-slide.swiper-slide-thumb-active .img {
  @apply border border-Primary-2;
}
.section-detail-pro-5 .tab-feature {
  @apply pb-4 lg:pb-0;
}
.section-detail-pro-5 .tab-feature ul {
  @apply flex-center gap-8 overflow-hidden overflow-x-auto;
}
.section-detail-pro-5 .tab-feature ul li {
  @apply relative pb-1;
}
.section-detail-pro-5 .tab-feature ul li:not(:last-child)::after {
  @apply content-[""] absolute top-1/2 rem:right-[-15px] lg:rem:right-[-20px];
  @apply translate-x-[-50%] translate-y-[-50%] bg-white block z-999 rem:w-[1px] rem:h-[20px];
}
.section-detail-pro-5 .tab-feature ul li a {
  @apply whitespace-nowrap cursor-pointer text-lg font-semibold text-neutral-200;
}
.section-detail-pro-5 .tab-feature ul li a::before {
  @apply content-[""] absolute bottom-0 left-0 w-0 h-[2px] bg-white transition-all duration-300 z-999;
}
.section-detail-pro-5 .tab-feature ul li a:hover {
  @apply text-white;
}
.section-detail-pro-5 .tab-feature ul li a.active {
  @apply text-white;
}
.section-detail-pro-5 .tab-feature ul li a.active::before {
  @apply w-full;
}
.section-detail-pro-5 .tab-feature ul li a:hover::before {
  @apply w-full;
}
.section-detail-pro-5 .gallery-main {
  @apply hidden;
}
.section-detail-pro-5 .gallery-main.tab-active {
  @apply block;
}
.section-detail-pro-5 .gallery-main .thumb-main .image {
  @apply cursor-pointer;
}
.section-detail-pro-5 .gallery-main .thumb-main .image img {
  @apply w-full h-auto object-cover;
}
.section-detail-pro-5 .gallery-main .wrapper-gallery .gallery-thumbs {
  @apply grid gap-4 grid-cols-2;
}
.section-detail-pro-5 .gallery-main .wrapper-gallery .gallery-thumbs .swiper-wrapper {
  display: contents;
}
.section-detail-pro-5 .gallery-main .wrapper-gallery .gallery-thumbs .swiper-slide .image {
  @apply cursor-pointer;
}
.section-detail-pro-5 .gallery-main .wrapper-gallery .gallery-thumbs .swiper-slide .image img {
  @apply rem:w-[228px] rem:h-[128px] object-cover;
}

.section-detail-pro-6 {
  @apply overflow-hidden;
}
.section-detail-pro-6 .tabslet-content {
  @apply hidden;
}
.section-detail-pro-6 .tabslet-content.active {
  @apply block;
}
.section-detail-pro-6 .col-left .wrapper-gallery {
  @apply w-full h-full relative md:rem:min-h-[664px];
}
@screen lg {
  .section-detail-pro-6 .col-left .wrapper-gallery .swiper {
    @apply absolute right-0 top-0 w-[66vw] h-full;
  }
}
.section-detail-pro-6 .col-left .wrapper-gallery .swiper .swiper-wrapper .swiper-slide .img {
  @apply img-full h-full w-full;
}
.section-detail-pro-6 .wrapper-slide-thumbs .swiper .swiper-slide .img {
  @apply relative;
}
.section-detail-pro-6 .wrapper-slide-thumbs .swiper .swiper-slide .img::before {
  content: "";
  @apply absolute-full border-2 z-2 border-transparent transition-all duration-300;
}
.section-detail-pro-6 .wrapper-slide-thumbs .swiper .swiper-slide.swiper-slide-thumb-active .img::before {
  @apply border-Primary-1;
}
.section-detail-pro-6 .list-tabs {
  @apply flex items-center mb-base;
}
.section-detail-pro-6 .list-tabs li {
  @apply items-center flex;
}
.section-detail-pro-6 .list-tabs li.active a {
  @apply text-Primary-1;
}
.section-detail-pro-6 .list-tabs li.active a::before {
  @apply w-full;
}
.section-detail-pro-6 .list-tabs li:first-child::before {
  @apply hidden;
}
.section-detail-pro-6 .list-tabs li::before {
  content: "";
  @apply rem:w-[1px] rem:h-[32px] bg-[#dcdcdc] inline-block mx-5;
}
.section-detail-pro-6 .list-tabs li a {
  @apply text-lg font-semibold text-[#7c7c7c] relative;
}
.section-detail-pro-6 .list-tabs li a:hover {
  @apply text-Primary-1;
}
.section-detail-pro-6 .list-tabs li a:hover::before {
  @apply w-full;
}
.section-detail-pro-6 .list-tabs li a::before {
  content: "";
  @apply absolute top-full left-0 w-0 rem:h-[1px] bg-Primary-1 transition-all duration-300;
}

.section-detail-pro-7 .swiper-slide {
  @apply duration-500 transition-all;
}
.section-detail-pro-7 .swiper-slide:hover .item-news {
  @apply border-b border-b-Primary-4;
}
.section-detail-pro-7 .swiper-slide:hover .item-news .image img {
  @apply duration-500 transition-all overflow-hidden;
}
.section-detail-pro-7 .swiper-slide:hover .item-news .image:hover img {
  @apply scale-110;
}
.section-detail-pro-7 .swiper-slide:hover .item-news:hover .title {
  @apply text-Primary-4;
}

.section-detail-pro-8 {
  @apply relative;
}
@media (max-width: 767.98px) {
  .section-detail-pro-8 .btn-main-from {
    @apply gap-5 flex-col-reverse;
  }
}
.section-detail-pro-8 .form-wrapper form {
  @apply md:grid-cols-2 grid gap-6;
}
.section-detail-pro-8 .form-wrapper form .f-message {
  @apply md:col-span-2;
}
.section-detail-pro-8 .wrapper-form {
  @apply flex flex-col gap-5;
}
.section-detail-pro-8 .wrapper-form .desc {
  @apply break-words;
}
.section-detail-pro-8 .wrapper-form .col-form {
  @apply flex items-center gap-6;
}
.section-detail-pro-8 .wrapper-form .col-form .item {
  @apply w-full;
}
.section-detail-pro-8 .wrapper-form .col-form .item input {
  @apply py-3 px-6 w-full border-none outline-none;
}
.section-detail-pro-8 .wrapper-form .col-full textarea {
  @apply py-3 px-6 w-full border-none outline-none;
}
.section-detail-pro-8 .wpcf7-response-output {
  @apply hidden;
}

.section-detail-pro-9 .home-cols-3-project .swiper-slide img {
  @apply duration-500 transition-all overflow-hidden;
}
.section-detail-pro-9 .home-cols-3-project .swiper-slide:hover img {
  @apply scale-110;
}
.section-detail-pro-9 .home-cols-3-project .swiper-slide:hover h5 {
  @apply text-Primary-3;
}
.section-detail-pro-9 .home-cols-3-project .desc span {
  @apply font-bold text-neutral-950;
}

.section-new-1 .list-tab-primary {
  @apply mt-8 lg:mt-0 max-w-full;
}
.section-new-1 .list-tab-primary ul {
  @apply flex gap-3 lg:gap-5 items-center overflow-auto;
}
.section-new-1 .list-tab-primary ul li {
  @apply first:ml-auto last:mr-auto;
}
.section-new-1 .list-tab-primary ul li.active a, .section-new-1 .list-tab-primary ul li.current a {
  @apply bg-Primary-1 text-Primary-2;
}
.section-new-1 .list-tab-primary ul li a {
  @apply text-Primary-1 border-solid border border-Primary-1;
  @apply font-medium text-lg py-3 px-5 flex-center whitespace-nowrap;
}
.section-new-1 .list-tab-primary ul li a.active, .section-new-1 .list-tab-primary ul li a:hover {
  @apply bg-Primary-1 text-Primary-2 transition-all duration-300;
}
.section-new-1 .wrapper-news .image {
  @apply overflow-hidden;
}
.section-new-1 .wrapper-news .image img {
  @apply w-full h-full object-cover duration-500 transition-all overflow-hidden;
}
.section-new-1 .wrapper-news .image:hover img {
  @apply scale-110;
}
.section-new-1 .wrapper-news .box-news {
  @apply pb-8 border-b border-Primary-1;
}
.section-new-1 .wrapper-news .box-news:hover img {
  @apply scale-110;
}
.section-new-1 .wrapper-news .box-news:hover .title {
  @apply text-Primary-6 transition-all duration-300;
}

.section-project-1 .list-tab-project {
  @apply shadow-[rgba(99,99,99,0.2)_0px_2px_8px_0px];
  @apply px-5 lg:px-0;
  @apply -translate-y-1/2 relative z-10 bg-white;
}
.section-project-1 .list-tab-project ul {
  @apply w-full sm:w-auto overflow-x-auto overflow-hidden max-w-full scroll-primary;
  @apply flex gap-5 lg:gap-10 pb-3;
}
.section-project-1 .list-tab-project ul li {
  @apply rem:py-5 lg:rem:px-10 relative;
}
.section-project-1 .list-tab-project ul li:first-child {
  @apply ml-auto;
}
.section-project-1 .list-tab-project ul li:last-child {
  @apply mr-auto;
}
.section-project-1 .list-tab-project ul li:not(:last-child)::after {
  @apply content-[""] absolute top-1/2 right-[-10px] xl:rem:right-[-20px] translate-y-[-50%] bg-neutral-300 block z-999 rem:w-[3px] rem:h-[20px];
}
.section-project-1 .list-tab-project ul li a {
  @apply whitespace-nowrap rem:pb-6 relative text-black uppercase text-base font-bold;
}
.section-project-1 .list-tab-project ul li a:hover {
  @apply text-Primary-4;
}
.section-project-1 .list-tab-project ul li a::after {
  @apply content-[""] absolute bottom-0 left-0 w-0 rem:h-[4px] bg-Primary-1 transition-all duration-300;
}
.section-project-1 .list-tab-project ul li.active a {
  @apply text-Primary-1;
}
.section-project-1 .list-tab-project ul li.active a::after {
  @apply w-full;
}
.section-project-1 .list-tab-project ul li a:hover::after {
  @apply w-full;
}
.section-project-1 .content .desc p {
  @apply pb-2;
}
.section-project-1 .content .desc span {
  @apply font-bold;
}

.section-project-2 .select-title {
  pointer-events: none;
}
.section-project-2 .form-wrapper .form-group {
  @apply lg:flex-1;
  @apply cursor-pointer py-3 px-6 w-full bg-neutral-50 border-b border-neutral-500 border-s-orange-50;
}
.section-project-2 .form-wrapper .form-group::after {
  content: "\f0d7";
  @apply font-awesome absolute rem:top-[10px] rem:right-[10px] text-Primary-1 text-base font-black transition-all duration-300;
}
.section-project-2 .form-wrapper .form-group ul.select-options {
  @apply overflow-y-scroll rem:max-h-[200px] hidden absolute top-full left-0 w-full bg-neutral-50 shadow-lg z-10;
}
.section-project-2 .form-wrapper .form-group ul.select-options li a {
  @apply border-b border-neutral-200 text-base text-neutral-500 font-medium block py-2 px-4 hover:bg-gray-200;
}
.section-project-2 .form-wrapper .form-group ul.select-options::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}
.section-project-2 .form-wrapper .form-group ul.select-options::-webkit-scrollbar {
  @apply rem:w-[6px] bg-neutral-200;
}
.section-project-2 .form-wrapper .form-group ul.select-options::-webkit-scrollbar-thumb {
  @apply bg-Primary-4 border-2 border-solid border-Primary-1;
}
.section-project-2 .form-wrapper .form-group.active .select-options {
  @apply block;
}

.project-item-featured.content-group .group-left img {
  @apply duration-500 transition-all overflow-hidden;
}
.project-item-featured.content-group .group-left:hover img {
  @apply scale-110;
}
.project-item-featured.content-group .content-feature .list-feature {
  @apply pb-3 flex justify-between border-b-[rgba(255,255,255,0.35)] border-b border-solid gap-5;
}
.project-item-featured.content-group .content-feature .list-feature .desc {
  @apply flex-1 text-right;
}
.project-item-featured.content-group .content-feature .list-feature .caption {
  @apply whitespace-nowrap;
}
.project-item-featured.content-group .content-feature .list-feature .info {
  @apply flex gap-4;
}

.section-project-3 .list-project img {
  @apply duration-500 transition-all overflow-hidden;
}
.section-project-3 .list-project:hover img {
  @apply scale-110;
}
.section-project-3 .list-project:hover h5 {
  @apply text-Primary-3;
}
.section-project-3 .list-project .desc {
  @apply flex items-center text-base font-normal text-neutral-950;
}
.section-project-3 .list-project .desc span {
  @apply font-bold;
}
.section-project-3 .pagination-wrapper ul {
  @apply flex-center gap-5;
}
.section-project-3 .pagination-wrapper ul li a {
  @apply pb-2 cursor-pointer text-neutral-500 rem:text-[20px] font-bold leading-1.3;
}
.section-project-3 .pagination-wrapper ul li a:hover {
  @apply text-[#009FDC] w-full border-b-3 border-solid border-b-[#009FDC];
}
.section-project-3 .pagination-wrapper ul li span {
  @apply pb-2 cursor-pointer text-neutral-500 rem:text-[20px] font-bold leading-1.3;
}
.section-project-3 .pagination-wrapper ul li span.active {
  @apply w-full border-b-3 border-solid border-b-[#009FDC] text-[#009FDC];
}
.section-project-3 .pagination-wrapper ul li.custom-hidden {
  @apply hidden;
}
.section-project-3 .loading-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.section-project-3 .loading-wrapper .loading {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.single-career-1 .box-info {
  @apply bg-Neutral-50 p-10;
}
.single-career-1 .box-info .wrapper-info img {
  @apply rem:w-[360px] rem:h-[300px];
}
.single-career-1 .box-info .wrapper-info .list-info {
  @apply pb-3 flex-between border-b border-b-[#DCDCDC] gap-10;
}
.single-career-1 .box-info .wrapper-info .list-info .caption {
  @apply text-black text-xl  font-bold whitespace-nowrap;
}
.single-career-1 .box-info .wrapper-info .list-info .desc {
  @apply text-black text-xl font-normal text-right;
}
.single-career-1 .box-info .wrapper-info .list-info:last-child .caption {
  @apply text-Primary-1;
}
.single-career-1 .sidebar-career .box-location .list-career {
  @apply flex flex-col gap-4 p-6 border-solid border-[#D9D9D9] border;
}
.single-career-1 .sidebar-career .box-location .list-career .list-info {
  @apply flex items-center gap-2;
}
.single-career-1 .sidebar-career .box-location .list-career .list-info .icon {
  @apply text-Primary-1;
}
.single-career-1 .sidebar-career .box-location .list-career .list-info .icon i {
  @apply text-base font-normal;
}
.single-career-1 .sidebar-career .box-location .list-career .list-info .caption {
  @apply text-Primary-1 text-base font-normal;
}
.single-career-1 .sidebar-career .box-location .list-career .list-info .date {
  @apply font-normal text-black;
}

#popup-apply {
  @apply rem:max-w-[920px] w-full lg:p-10 md:p-5 p-[15px];
}
#popup-apply .carousel__button.is-close {
  background: linear-gradient(180deg, #01A6F0 0%, #01699E 100%);
  @apply cursor-pointer p-3 rem:w-[40px] rem:h-[40px] flex-center gap-2 top-0 rounded-none;
}
#popup-apply form {
  @apply grid md:grid-cols-2 gap-x-5 gap-y-3;
}
#popup-apply form .f-message {
  @apply md:col-span-2;
}
#popup-apply form label {
  @apply hidden;
}
#popup-apply form input, #popup-apply form textarea {
  @apply rem:h-[46px] border border-Neutral-200 px-5 py-3 font-medium text-Neutral-500 hocus:border-Primary-1 transition-all duration-300 w-full;
}
#popup-apply form textarea {
  @apply lg:rem:h-[152px] h-[140px];
}

.section-breadcrumb .breadcrumb-wrapper p {
  @apply h-[calc(48/1920*100rem)] flex items-center overflow-auto;
}
.section-breadcrumb .breadcrumb-wrapper p a:first-child {
  @apply text-0 flex leading-[1] !important;
}
.section-breadcrumb .breadcrumb-wrapper p a:first-child::before {
  content: "\f015";
  @apply font-awesome text-lg font-light;
}
.section-breadcrumb .breadcrumb-wrapper p a, .section-breadcrumb .breadcrumb-wrapper p span {
  @apply text-base text-neutral-500 whitespace-nowrap;
}
.section-breadcrumb .breadcrumb-wrapper p span.separator {
  @apply text-0 inline-flex mx-3 leading-[1] !important;
}
.section-breadcrumb .breadcrumb-wrapper p span.separator::before {
  content: "\f054";
  @apply font-awesome font-light text-base mx-1;
}

.single-news .list-tab-primary {
  @apply mt-0 !important;
  @apply mt-8 lg:mt-0 max-w-full;
}
.single-news .list-tab-primary ul {
  @apply flex gap-3 lg:gap-5 items-center overflow-auto;
}
.single-news .list-tab-primary ul li {
  @apply first:ml-auto last:mr-auto;
}
.single-news .list-tab-primary ul li.active a, .single-news .list-tab-primary ul li.current a {
  @apply bg-Primary-1 text-Primary-2;
}
.single-news .list-tab-primary ul li a {
  @apply text-Primary-1 border-solid border border-Primary-1;
  @apply font-medium text-lg py-3 px-5 flex-center whitespace-nowrap;
}
.single-news .list-tab-primary ul li a.active, .single-news .list-tab-primary ul li a:hover {
  @apply bg-Primary-1 text-Primary-2 transition-all duration-300;
}
.single-news .new-detail .date {
  @apply justify-end w-full flex items-center gap-5 whitespace-nowrap;
}
.single-news .new-detail .date::after {
  @apply hidden lg:block content-[""] h-[1px] w-full bg-neutral-300;
}
.single-news .new-detail .content-details {
  @apply lg:text-xl;
}
.single-news .new-detail .content-details span {
  @apply text-black font-bold;
}
.single-news .new-detail .content-details p {
  @apply text-neutral-950;
}
.single-news .new-detail .content-details img {
  @apply mx-auto flex-center;
}
.single-news .new-detail .news-social {
  @apply static xl:absolute rem:top-[30px] right-full mr-5 h-full;
}
.single-news .new-detail .news-social ul {
  @apply sticky flex flex-row xl:flex-col mt-4 xl:mt-0 gap-5 top-40;
}
.single-news .new-detail .news-social ul li {
  background: linear-gradient(180deg, #01A6F0 0%, #01699E 100%);
  @apply rounded-full rem:w-[40px] rem:h-[40px] flex-center gap-3 p-3;
}
.single-news .new-detail .news-social ul li i {
  @apply text-Primary-2;
}
.single-news .new-detail .news-social ul li img {
  @apply flex-center;
}

.single-news-relative .swiper-slide {
  @apply pb-8 border-b border-Primary-1;
}
.single-news-relative .swiper-slide .title {
  @apply transition-all duration-300;
}
.single-news-relative .swiper-slide:hover .image {
  @apply duration-500 transition-all overflow-hidden;
}
.single-news-relative .swiper-slide:hover .image img {
  @apply scale-110;
}
.single-news-relative .swiper-slide:hover .title {
  @apply text-Primary-6;
}

.section-home-banner .item-banner .banner-content h2 {
  -webkit-background-clip: text !important;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  background: transparent;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: #fff;
  -webkit-text-stroke-width: 0.1041666667rem;
  @apply leading-tight text-[45px] md:rem:text-[96px] font-extrabold text-white transition-all duration-300 w-fit;
}
.section-home-banner .item-banner .banner-content h2:hover {
  background: #ffde00;
  -webkit-text-stroke-color: #ffde00;
  @apply text-Primary-2;
}
.section-home-banner .swiper-pagination .swiper-pagination-bullet {
  @apply w-2 h-2 bg-white rounded-none;
}
.section-home-banner .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply bg-white;
}

.section-home-about {
  transition: background 0s ease-in-out;
  @apply overflow-hidden;
}
.section-home-about .item-col-left .format-content p {
  @apply break-inside-avoid;
}
.section-home-about .item-col-right, .section-home-about .item-col-left {
  @apply xl:text-[1vw] md:text-[2vw] text-[1vw];
}
.section-home-about .item-col-right .wrap-items, .section-home-about .item-col-left .wrap-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  aspect-ratio: 1;
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  @apply w-full xl:h-full;
}
.section-home-about .item-col-right .wrap-items .item, .section-home-about .item-col-left .wrap-items .item {
  @apply h-full;
}
.section-home-about .item-col-right .wrap-items .item:nth-child(1), .section-home-about .item-col-left .wrap-items .item:nth-child(1) {
  grid-area: 1/1/3/3;
}
.section-home-about .item-col-right .wrap-items .item:nth-child(2), .section-home-about .item-col-left .wrap-items .item:nth-child(2) {
  grid-area: 1/3/2/4;
}
.section-home-about .item-col-right .wrap-items .item:nth-child(3), .section-home-about .item-col-left .wrap-items .item:nth-child(3) {
  grid-area: 2/3/3/4;
}
.section-home-about .item-col-right .wrap-items .item:nth-child(4), .section-home-about .item-col-left .wrap-items .item:nth-child(4) {
  grid-area: 3/1/4/3;
}
.section-home-about .item-col-right .wrap-items .item:nth-child(5), .section-home-about .item-col-left .wrap-items .item:nth-child(5) {
  grid-area: 3/3/4/4;
}
.section-home-about .item-col-right .item-edge-dragon::before, .section-home-about .item-col-left .item-edge-dragon::before {
  content: "";
  background-image: url("../img/home-about-dragon.png");
  background-size: 100% 100%;
  @apply w-[400px] h-[300px] md:em:w-[962px] md:em:h-[581px];
  @apply opacity-60;
  @apply absolute right-0 bottom-0;
  @apply inline-block;
}
@media (max-width: 767.98px) {
  .section-home-about .item-col-right .item-edge-dragon::before, .section-home-about .item-col-left .item-edge-dragon::before {
    width: 65%;
    background-position: bottom right;
    height: 92%;
    content: "";
    background-repeat: no-repeat;
    transform: translateY(36%);
  }
}
@screen md {
  .section-home-about .item-col-right .item-edge-dragon::before, .section-home-about .item-col-left .item-edge-dragon::before {
    transform: translateY(40%);
  }
}

.section-home-field-activity {
  @apply relative z-2;
}
.section-home-field-activity::before {
  content: "";
  @apply absolute top-0 left-0 w-full h-full bg-black/60 z-2;
}
.section-home-field-activity .img-bg {
  @apply opacity-0 transition-all duration-300;
}
.section-home-field-activity .img-bg.active {
  @apply opacity-100;
}
.section-home-field-activity .list-activity li.item-activity.hide a {
  @apply opacity-50;
}
.section-home-field-activity .list-activity li.item-activity.hide a::before {
  @apply w-0  overflow-hidden;
}
.section-home-field-activity .list-activity li.item-activity a {
  @apply flex items-center gap-3;
}
.section-home-field-activity .list-activity li.item-activity a::before {
  content: "\f324";
  @apply font-awesome text-2xl font-light transition-all duration-300 w-6;
}
.section-home-field-activity .item-box {
  @apply overflow-hidden rounded-lg;
}
.section-home-field-activity .item-box .content {
  @apply bg-Primary-3/80 rounded-b-lg;
  @apply transition-all duration-300 z-2;
}
@screen lg {
  .section-home-field-activity .item-box .content {
    transform: translateY(100%);
    @apply bg-transparent;
  }
}
@screen lg {
  .section-home-field-activity .item-box .content .wrap {
    @apply opacity-0 transition-all duration-300;
  }
}
.section-home-field-activity .item-box:hover .content {
  @apply translate-y-0 bg-Primary-3/80;
}
.section-home-field-activity .item-box .item-image.active {
  @apply border-Primary-2;
}
.section-home-field-activity .swiper-pagination {
  @apply static bg-[#D9D9D9];
}
.section-home-field-activity .swiper-pagination .swiper-pagination-progressbar-fill {
  @apply bg-Primary-4;
}
.section-home-field-activity .wrap-title-gradient-carousel {
  @apply flex justify-between items-center;
}
.section-home-field-activity .wrap-title-gradient-carousel .title {
  -webkit-background-clip: text !important;
  font-weight: 800;
  letter-spacing: -0.2rem;
  text-transform: uppercase;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: #969BA0;
  -webkit-text-stroke-width: 0.1041666667rem;
  @apply transition-all duration-300 xl:rem:text-[96px] lg:rem:text-[80px] md:text-[64px] text-[48px];
}
.section-home-field-activity .wrap-title-gradient-carousel .title:hover {
  -webkit-text-stroke-color: #003180;
  @apply bg-Primary-3;
}
.section-home-field-activity .wrap-slide-text {
  @apply overflow-hidden w-full;
}
.section-home-field-activity .wrap-slide-text .scroll-container {
  min-width: calc(300px * var(--quantity-mobile) - 300px);
  @apply relative flex w-full overflow-hidden place-content-end mt-10 xl:rem:h-[150px] h-[100px];
}
@screen md {
  .section-home-field-activity .wrap-slide-text .scroll-container {
    min-width: calc(31.25rem * var(--quantity-mobile) - 31.25rem);
  }
}
.section-home-field-activity .wrap-slide-text .scroll-container .item {
  left: 100%;
  height: 100%;
  @apply md:w-[31.25rem] absolute px-[8px] w-[300px];
}
@media (max-width: 767.98px) {
  .section-home-field-activity .wrap-slide-text .scroll-container .item {
    animation: autoRunLeftMobile calc(var(--quantity-mobile) * 2s) linear infinite;
    animation-delay: calc(var(--quantity-mobile) * 2s / var(--quantity-mobile) * (var(--position-mobile) - 1) * -1);
  }
}
@screen md {
  .section-home-field-activity .wrap-slide-text .scroll-container .item {
    animation: autoRunLeft calc(var(--quantity-mobile) * 3s) linear infinite;
    animation-delay: calc(var(--quantity-mobile) * 3s / var(--quantity-mobile) * (var(--position-mobile) - 1) * -1);
  }
}
.section-home-field-activity .wrap-slide-text .scroll-container .item .title {
  @apply text-center;
}

@keyframes autoRunLeft {
  from {
    left: 100%;
  }
  to {
    left: -31.25rem;
  }
}
@keyframes autoRunLeftMobile {
  from {
    left: 100%;
  }
  to {
    left: -300px;
  }
}
.section-home-news .list-tab-primary {
  @apply mt-8 lg:mt-0 max-w-full;
}
.section-home-news .list-tab-primary ul {
  @apply flex gap-3 lg:gap-5 items-center overflow-auto;
}
.section-home-news .list-tab-primary ul li {
  @apply first:ml-auto last:mr-auto;
}
.section-home-news .list-tab-primary ul li.active a, .section-home-news .list-tab-primary ul li.current a {
  @apply bg-Primary-1 text-Primary-2;
}
.section-home-news .list-tab-primary ul li a {
  @apply text-Primary-1 border-solid border border-Primary-1;
  @apply font-medium text-lg py-3 px-5 flex-center whitespace-nowrap;
}
.section-home-news .list-tab-primary ul li a.active, .section-home-news .list-tab-primary ul li a:hover {
  @apply bg-Primary-1 text-Primary-2 transition-all duration-300;
}

.section-home-footer footer {
  @apply xl:py-0 !important;
}

.section-home-project .swiper-slide {
  @apply h-auto w-fit !important;
}
.section-home-project .wrap-button-slide .btn-prev, .section-home-project .wrap-button-slide .btn-next {
  @apply top-[40%];
}
.section-home-project .item-project-featured {
  @apply flex flex-col justify-end h-full relative;
}
.section-home-project .item-project-featured::before {
  content: "";
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
  @apply absolute  pointer-events-none z-1 h-[100px] bottom-0 left-0 w-full;
}
.section-home-project .item-project-featured:hover .content {
  @apply translate-y-0 bg-Primary-3/80;
}
.section-home-project .item-project-featured:hover .content .wrap {
  @apply opacity-100;
}
.section-home-project .item-project-featured .img {
  @apply rem:max-w-[480px] rem:max-h-[640px];
}
.section-home-project .item-project-featured .img img {
  @apply max-w-full max-h-full;
}
.section-home-project .item-project-featured .content {
  @apply bg-Primary-3/80;
  @apply transition-all duration-300 z-2;
}
@screen lg {
  .section-home-project .item-project-featured .content {
    transform: translateY(var(--height-item-var-height));
    @apply bg-transparent;
  }
}
@screen lg {
  .section-home-project .item-project-featured .content .wrap {
    @apply opacity-0 transition-all duration-300;
  }
}
.section-home-project .indicator-swipe {
  @apply right-[15px] lg:right-25 top-0 absolute -translate-y-1/2;
}