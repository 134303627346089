@layer utilities
	@import 'src/components/common/Title.sass'
	.section-py
		@apply py-10 lg:py-15 xl:py-20
	.no-gutters
		@apply mx-0
		> *
			@apply px-0
	.lozad
		opacity: 0
		filter: blur(10px)
		transition: .3s all ease-in-out
		&[data-loaded="true"]
			opacity: 1
			filter: blur(0)
	.container-fullpage
		@apply w-full lg:px-25 px-[15px]
	.gradient-primary
		background: linear-gradient(180deg, #01A6F0 0%, #01699E 100%)
	.scroll-primary
		+scroll()
	.form-message
		.alert
			@apply  w-full p-2 border-2
			&.alert-danger
				@apply  border-red-500
			&.alert-success
				@apply  border-green-500
	.desktop-only
		@apply hidden lg:block
	.mobile-only
		@apply block lg:hidden
	.img-contain
		img
			@apply object-contain
