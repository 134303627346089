.title-56
	@apply text-[28px] md:text-4xl lg:text-5xl xl:rem:text-[56px] font-bold uppercase leading-normal
.title-64
	@apply text-4xl md:text-5xl lg:text-6xl xl:text-64 font-bold uppercase leading-normal
.title-40
	@apply text-[32px] lg:text-5xl font-bold
.title-48
	@apply text-4xl lg:text-6xl leading-tight font-bold
.title-28
	@apply text-2xl lg:rem:text-[28px] font-bold leading-tight
