header
	@apply h-20 bg-Primary-6 sticky lg:fixed top-0 left-0 right-0 z-[1000] transition-all duration-300
	nav
		@apply h-full text-white flex justify-between items-center lg:px-25 px-[15px]
		.header-left
			@apply flex items-center gap-3 relative z-2
		.header-logo
			@apply left-1/2 -translate-x-1/2 absolute
			a
				@apply relative pt-5 block
				&::before
					content: ''
					border-radius: 50%
					@apply w-[15.6875rem] h-[11.041667rem] md:rem:w-[378px] md:rem:h-[212px] bg-Primary-6 absolute top-0 left-1/2 -translate-x-1/2 -z-1 -translate-y-[43%] pointer-events-none
				img
					@apply rem:w-[144px]
		.header-hotline
			@apply rounded-full h-10 items-center gap-2 px-4 border border-Primary-2 text-Primary-2 hidden md:flex
		.header-hambuger
			box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.50) inset
			@apply w-15 h-15 flex-center bg-white rounded-5
			.hambuger
				box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.50)
				background: linear-gradient(180deg, #01A6F0 0%, #01A6F0 100%)
				@apply rounded-xl p-2 pointer-events-none
				@apply w-10 h-10 flex flex-col gap-2
				span
					@apply h-[2px] w-6 bg-white rounded-full
	.header-hambuger
		@apply transition-all duration-300
		&:hover
			@apply bg-Primary-2
			.hambuger
				background: linear-gradient(180deg, #01A6F0 0%, #01699E 100%)
				span
					&:nth-child(1)
						@apply w-[60%]
					&:nth-child(2)
						@apply w-[50%]
					&:nth-child(3)
						@apply w-[40%]
				&::before
					@apply -translate-x-0
		.hambuger
			@apply relative overflow-hidden z-2
			&::before
				content: ''
				@apply w-[70%] h-full bg-Primary-4 absolute top-0 left-0 -z-1 -translate-x-full transition-all duration-300
// Header Global
.header-language
	@apply relative
	&:hover
		ul
			@apply opacity-100 pointer-events-auto
	&>ul
		&>li
			&>a
				@apply text-sm uppercase text-white font-medium
				&::after
					content: '\f078'
					@apply font-awesome font-light text-white ml-1 text-xs
		&>ul
			@apply transition-all duration-300
			@apply opacity-0 absolute top-full left-1/2 -translate-x-1/2 pointer-events-none
			&>li
				&>a
					@apply flex-center bg-Primary-2 px-2 py-0.25 text-sm text-black hover:bg-Primary-2/80
.header-search
	@apply flex-center w-10 h-10 border-Primary-2 rounded-full border cursor-pointer
	i
		@apply text-Primary-2 pointer-events-none

// Header Sidebar Menu
.header-sidebar-menu
	@apply fixed right-0 top-0 h-screen lg:rem:w-[825px] w-screen
	@apply px-[15px] lg:px-20 py-10 text-white z-[1001] flex flex-col items-end gap-5 lg:gap-10 transition-all duration-300
	@apply translate-x-full
	@apply gradient-primary
	&::before
		content: ''
		background-image: url(../img/header-bg.png)
		background-position: left bottom
		background-size: 29.4791666667rem 31.09375rem
		background-repeat: no-repeat
		@apply absolute top-0 left-0 w-full h-full pointer-events-none
	.close
		@apply text-Primary-2 text-3xl lg:text-32 cursor-pointer
	.header-menu
		ul
			li
				&[class*="current"]
					&>a
						@apply text-Primary-2
						&::before
							@apply w-full bg-Primary-2
				a
					@apply relative
					&::before
						content: ''
						@apply w-[0px] h-[1px] bg-white absolute top-full mt-1 left-0 transition-all duration-300
					&:hover
						@apply opacity-100
						&::before
							@apply w-full right-0 left-auto
		&>ul
			@apply flex flex-col lg:gap-8 gap-3
			&>li
				@apply text-right
				&>a
					@apply text-3xl lg:rem:text-[40px] inline-block text-right
				&>ul
					@apply flex-col mt-10 hidden
					&>li
						@apply text-right mb-5 last:mb-0
						&>a
							@apply rem:text-[28px] text-right opacity-50
	.header-social
		ul
			@apply flex items-center gap-2
			li
				a
					@apply flex-center rounded-full w-10 h-10 text-Primary-3 bg-white hover:bg-Primary-2 transition-all duration-300
body.isOpenMenu
	.header-sidebar-menu
		@apply translate-x-0
	.overlay-black
		@apply opacity-100 pointer-events-auto
body
	&.hidden-fullpage
		header
			@apply -translate-y-[200%]
		.header-toggle-fullpage
			@apply translate-y-0
.header-toggle-fullpage
	@apply fixed right-5 top-0 flex flex-col items-center z-[999]
	@apply -translate-y-[100%] transition-all duration-300
	&::before
		content: ''
		@apply w-[1px] h-[50px] bg-Primary-2 inline-block
	.tag
		writing-mode: vertical-rl
		@apply border-Primary-2 border-2 bg-Primary-6 px-2 py-2 text-Primary-2 text-sm cursor-pointer

