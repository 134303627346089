.section-career-1
	@apply relative z-2
	@apply rem:min-h-[680px] flex-center flex-col
	&::before
		content: ""
		background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) 14.26%, #F4F4F4 62.56%)
		@apply absolute inset-0 w-full h-full -z-1

.section-career-2
	.item-box
		@apply border border-neutral-200 border-solid
		.wrapper-box
			@apply transition-all duration-200 cursor-pointer py-10 px-5
			.image
				svg
					@apply fill-[#989898]
			&:hover
				@apply bg-Primary-1
				.image
					svg
						path
							@apply fill-white
				.title
					@apply text-Primary-2
				.desc
					@apply text-white
.section-career-3
	.content-table
		table
			@apply w-full
			th
				@apply p-2 text-white uppercase border-solid border border-b-[#CEDBE6] bg-Primary-1
				@apply text-xl font-bold px-5
			tr
				@apply relative
				&:hover
					td
						@apply text-Primary-1
				td
					@apply text-lg text-neutral-950 py-3 px-5 text-center border-solid border border-b-[#CEDBE6]
					@apply transition-all duration-200
					a
						@apply inline-block
					&:nth-child(1)
						@apply text-black font-bold
					&:nth-child(2)
						@apply text-left whitespace-nowrap lg:whitespace-normal
					&:nth-child(3)
						@apply whitespace-nowrap
					&:last-child
						@apply rem:w-[220px]
				&:nth-child(even)
					@apply bg-[#f6f6f6]
