@import 'plugins/Asp/Form.sass'
@import 'plugins/Asp/Search.sass'
@import 'plugins/Asp/Login.sass'
@import 'plugins/Asp/Sitemap.sass'
@import 'plugins/Asp/Toolbar.sass'
.notfound
	.notfound-404 h1
		@apply bg-red-500 bg-none #{!important}
	a
		@apply bg-red-500 bg-none shadow-none #{!important}
