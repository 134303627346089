.section-nav
	ul
		@apply flex-between gap-6 lg:gap-0 overflow-hidden overflow-x-auto
		li
			@apply relative
			&.active
				a
					@apply text-white
					&::before
						@apply w-full
			&:not(:last-child)::after
				@apply content-[""] absolute top-1/2 rem:right-[-15px] lg:rem:right-[-70px] translate-x-[-50%] translate-y-[-50%] bg-white block z-999 rem:w-[3px] rem:h-[20px]
			a
				@apply whitespace-nowrap relative py-6 uppercase text-base font-bold text-white block
				&::before
					@apply content-[''] absolute rem:bottom-[-2px] left-0 w-0 h-[4px] bg-white transition-all duration-300 z-999
				&:hover::before
					@apply w-full
			// .inSection
			// 	&:not(:last-child)::after
			// 		@apply content-[""] absolute top-1/2 rem:right-[-15px] lg:rem:right-[-70px] translate-x-[-50%] translate-y-[-50%] bg-white block z-999 rem:w-[3px] rem:h-[20px]

.section-detail-pro-1
	.wrapper-info
		.item-left,
		.item-right
			h4
				@apply pb-5 uppercase text-xl font-bold text-neutral-950
			ul
				@apply pl-0 #{!important}
			ul
				@apply flex flex-col gap-3
				li
					@apply pb-3 border-b border-neutral-200 w-full flex md:items-center flex-col md:flex-row
					&:last-child
						@apply border-none
					span
						@apply font-bold rem:min-w-[120px]
					p
						@apply md:pl-2 pl-0
	.wrapper-action
		.list-tutorial
			.btn-ctn
				@apply my-4 sm:my-0 cursor-pointer gap-2 py-3 px-6 flex-center
		.list-social
			ul
				@apply flex items-center gap-4 flex-wrap
				li
					@apply p-3
					a
						@apply text-lg font-semibold text-white flex gap-2 flex-row-reverse items-center
					i
						@apply text-xl
					&:nth-child(1)
						@apply bg-[#3B5696]
					&:nth-child(2)
						@apply bg-[#F40D1A]
	.sidebar-info
		@apply h-fit shadow-[4px_4px_24px_0px_rgba(0,0,0,0.08)] flex flex-col gap-4
		.info-item
			@apply border-b border-b-neutral-200
			&:last-child
				@apply border-none
			.ctn
				@apply flex items-center gap-2
				i
					@apply text-xl font-normal text-neutral-950
				span
					@apply uppercase text-base font-normal text-neutral-950
			.counter
				@apply text-28 font-bold text-[#009FDC]

.section-detail-pro-3
	min-height: r(640px)
	@apply relative
	@screen lg
		.block-map
			@apply lg:w-5/12 absolute top-0 left-0 h-full
	.box-item
		@apply px-0 p-5 lg:px-5 flex-center flex-col border-l-0 lg:border-l-3 border-b-neutral-300
		&:first-child
			@apply pl-0 border-none
		&:last-child
			@apply pr-0
	.block-map
		iframe
			@apply w-full h-full

.section-detail-pro-4
	.tab-feature
		ul
			@apply flex-center gap-6 overflow-hidden overflow-x-auto
			li
				@apply relative pb-1
				&:not(:last-child)::after
					@apply content-[""] absolute top-1/2 rem:right-[-15px] lg:rem:right-[-12px] translate-x-[-50%] translate-y-[-50%]  bg-neutral-400 z-999 rem:w-[2px] rem:h-[20px]
				a
					@apply whitespace-nowrap cursor-pointer text-lg font-semibold text-neutral-500
					&::before
						@apply content-[''] absolute bottom-0 left-0 w-0 h-[2px] bg-[#009FDC] transition-all duration-300 z-999
					&:hover
						@apply text-[#009FDC]
					&.active
						@apply text-[#009FDC]
						&::before
							@apply w-full
					&:hover::before
						@apply w-full
	.tab-content
		@apply hidden
		&.active
			@apply block
			.home-cols-feature
				.swiper-slide
					background: rgba(255, 255, 255, 0.80)
					backdrop-filter: blur(6px)
					@apply backdrop-blur-[6px] transition-opacity duration-[0.3s] ease-[ease]
					@apply opacity-60 overflow-hidden
					&::before
						backdrop-filter: blur(6px)
						background: rgba(255, 255, 255, 0.80)
						@apply opacity-80 content-[""] absolute-full h-full
					.image
						@apply w-full
					&-active
						@apply opacity-100 duration-[0.3s] ease-[ease]
					&:not(.swiper-slide-active)::before
						@apply block
					&.swiper-slide-active
						&::before
							@apply hidden

.section-detail-pro-5
	.slide-main
		@apply xl:rem:pr-[45px]
	.wrap-button-slide
		.btn-prev
			@apply left-0 right-[auto] -translate-x-1/2
		.btn-next
			@apply right-0 left-[auto] translate-x-1/2
	.slide-thumb
		.swiper-slide
			.img
				@apply border border-transparent
			&.swiper-slide-thumb-active
				.img
					@apply border border-Primary-2
	.tab-feature
		@apply pb-4 lg:pb-0
		ul
			@apply flex-center gap-8 overflow-hidden overflow-x-auto
			li
				@apply relative pb-1
				&:not(:last-child)::after
					@apply content-[""] absolute top-1/2 rem:right-[-15px] lg:rem:right-[-20px]
					@apply translate-x-[-50%] translate-y-[-50%] bg-white block z-999 rem:w-[1px] rem:h-[20px]
				a
					@apply whitespace-nowrap cursor-pointer text-lg font-semibold text-neutral-200
					&::before
						@apply content-[''] absolute bottom-0 left-0 w-0 h-[2px] bg-white transition-all duration-300 z-999
					&:hover
						@apply text-white
					&.active
						@apply text-white
						&::before
							@apply w-full
					&:hover::before
						@apply w-full
	.gallery-main
		@apply hidden
		&.tab-active
			@apply block
		.thumb-main
			.image
				@apply cursor-pointer
				img
					@apply w-full h-auto object-cover
		.wrapper-gallery
			.gallery-thumbs
				@apply grid gap-4 grid-cols-2
				.swiper-wrapper
					display: contents
				.swiper-slide
					.image
						@apply cursor-pointer
						img
							@apply rem:w-[228px] rem:h-[128px] object-cover

.section-detail-pro-6
	@apply overflow-hidden
	.tabslet-content
		@apply hidden
		&.active
			@apply block
	.col-left
		.wrapper-gallery
			@apply w-full h-full relative md:rem:min-h-[664px]
			.swiper
				@screen lg
					@apply absolute right-0 top-0 w-[66vw] h-full
				.swiper-wrapper
					.swiper-slide
						.img
							@apply img-full h-full w-full
	.wrapper-slide-thumbs
		.swiper
			.swiper-slide
				.img
					@apply relative
					&::before
						content: ''
						@apply absolute-full border-2 z-2 border-transparent transition-all duration-300
				&.swiper-slide-thumb-active
					.img
						&::before
							@apply border-Primary-1
	.list-tabs
		@apply flex items-center mb-base
		li
			@apply items-center flex
			&.active
				a
					@apply text-Primary-1
					&::before
						@apply w-full
			&:first-child
				&::before
					@apply hidden
			&::before
				content: ''
				@apply rem:w-[1px] rem:h-[32px] bg-[#dcdcdc] inline-block mx-5
			a
				@apply text-lg font-semibold text-[#7c7c7c] relative
				&:hover
					@apply text-Primary-1
					&::before
						@apply w-full
				&::before
					content: ''
					@apply absolute top-full left-0 w-0 rem:h-[1px] bg-Primary-1 transition-all duration-300

.section-detail-pro-7
	.swiper-slide
		@apply duration-500 transition-all
		&:hover
			.item-news
				@apply border-b border-b-Primary-4
				.image
					img
						@apply duration-500 transition-all overflow-hidden
					&:hover img
						@apply scale-110
				&:hover .title
					@apply text-Primary-4


.section-detail-pro-8
	@apply relative
	.btn-main-from
		@media (max-width: 767.98px)
			@apply gap-5 flex-col-reverse
	.form-wrapper
		form
			@apply md:grid-cols-2 grid gap-6
			.f-message
				@apply md:col-span-2
	.wrapper-form
		@apply flex flex-col gap-5
		.desc
			@apply break-words
		.col-form
			@apply flex items-center gap-6
			.item
				@apply w-full
				input
					@apply py-3 px-6 w-full border-none outline-none
		.col-full
			textarea
				@apply py-3 px-6 w-full border-none outline-none
	.wpcf7-response-output
		@apply hidden

.section-detail-pro-9
	.home-cols-3-project
		.swiper-slide
			img
				@apply duration-500 transition-all overflow-hidden
			&:hover img
				@apply scale-110
			&:hover h5
				@apply text-Primary-3
		.desc
			span
				@apply font-bold text-neutral-950

