.section-home-about
	transition: background 0s ease-in-out
	@apply overflow-hidden

	.item-col-left
		.format-content
			p
				@apply break-inside-avoid
	.item-col-right,.item-col-left
		@apply xl:text-[1vw] md:text-[2vw] text-[1vw]
		.wrap-items
			display: grid
			grid-template-columns: repeat(3, 1fr)
			aspect-ratio: 1
			grid-template-rows: repeat(3, 1fr)
			grid-column-gap: 0px
			grid-row-gap: 0px
			@apply w-full xl:h-full
			.item
				@apply h-full
				&:nth-child(1)
					grid-area: 1 / 1 / 3 / 3
				&:nth-child(2)
					grid-area: 1 / 3 / 2 / 4
				&:nth-child(3)
					grid-area: 2 / 3 / 3 / 4
				&:nth-child(4)
					grid-area: 3 / 1 / 4 / 3
				&:nth-child(5)
					grid-area: 3 / 3 / 4 / 4
		.item-edge-dragon
			&::before
				content: ''
				background-image: url('../img/home-about-dragon.png')
				background-size: 100% 100%
				@media (max-width: 767.98px)
					width: 65%
					background-position: bottom right
					height: 92%
					content: ""
					background-repeat: no-repeat
					transform: translateY(36%)
				@screen md
					transform: translateY(40%)
				@apply w-[400px] h-[300px] md:em:w-[962px] md:em:h-[581px]
				@apply opacity-60
				@apply absolute right-0 bottom-0
				@apply inline-block
