@function r($size)
	@return (calc($size / 16px * 1rem))
@function rr($size)
	@return (calc($size / 1920px * 100rem))
@function re($size)
	@return (calc($size / 1920px * 100em))
=img-ratio($ratio,$fit:cover)
	position: relative
	display: block
	height: 0
	overflow: hidden
	padding-top: $ratio *100%
	img,iframe,video
		position: absolute
		width: 100%
		height: 100%
		top: 0
		left: 0
		object-fit: $fit
		transition: .3s ease-in-out all
=line($line)
	overflow: hidden
	display: -webkit-box
	text-overflow: ellipsis
	-webkit-line-clamp: $line
	-webkit-box-orient: vertical
=hover-underline($ratio, $color)
	position: relative
	transition: all .3s ease
	background-image: linear-gradient(180deg,transparent $ratio, $color 0)
	background-repeat: no-repeat
	background-size: 0 100%
	&:hover
		background-size: 100% 100%
=type-none
	list-style-type: none
	margin: 0
	padding: 0
=lh-fz($fontSize, $lineHeight)
	line-height: calc( $lineHeight / $fontSize) !important
=scroll
	&::-webkit-scrollbar
		width: 5px
		height: 5px
	&::-webkit-scrollbar-track
		@apply bg-neutral-300
	&::-webkit-scrollbar-thumb
		@apply bg-Primary-2
=transition-base
	transition: transform .6s cubic-bezier(.75,0,.175,1),visibility .6s cubic-bezier(.75,0,.175,1)
=list-tab
	@apply mt-8 lg:mt-0 max-w-full
	ul
		@apply flex gap-3 lg:gap-5 items-center overflow-auto
		li
			@apply first:ml-auto last:mr-auto
			&.active,&.current
				a
					@apply bg-Primary-1 text-Primary-2
			a
				@apply text-Primary-1 border-solid border border-Primary-1
				@apply font-medium text-lg py-3 px-5 flex-center whitespace-nowrap
				&.active,
				&:hover
					@apply bg-Primary-1 text-Primary-2 transition-all duration-300
