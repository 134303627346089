.global-breadcrumb
	@apply bg-[#f1f1f1] border-b border-white
	p
		@apply h-[calc(48/1920*100rem)] flex items-center overflow-auto
		a
			&:first-child
				@apply text-0
				&::before
					content: '\f015'
					@apply font-awesome text-sm
		a,span
			@apply text-sm text-[#333333] whitespace-nowrap
		span.separator
			@apply text-0 inline-flex
			&::before
				content: "\f054"
				@apply font-awesome font-normal text-sm mx-2

// banner new

.banner-global
	&::before
		background: linear-gradient(180deg, transparent 32.57%, rgba(0, 0, 0, .75))
		@apply content-[""] absolute-full h-full z-2
	.breadcrumb-wrapper
		@apply absolute bottom-[30px] lg:rem:bottom-[35px] left-0 w-full z-10
		p
			@apply h-[calc(48/1920*100rem)] flex items-center overflow-auto
			a
				&:first-child
					@apply text-0 flex leading-[1] #{!important}
					&::before
						content: '\f015'
						@apply font-awesome text-lg font-light
			a,span
				@apply text-base text-white whitespace-nowrap
			span.separator
				@apply text-0 inline-flex mx-3 leading-[1] #{!important}
				&::before
					content: "\f054"
					@apply font-awesome font-light text-base mx-1
	video
		@apply rem:h-[720px]


