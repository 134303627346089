.section-home-field-activity
	@apply relative z-2
	&::before
		content: ''
		@apply absolute top-0 left-0 w-full h-full bg-black/60 z-2
	.img-bg
		@apply opacity-0 transition-all duration-300
		&.active
			@apply opacity-100
	.list-activity
		li.item-activity
			&.hide
				a
					@apply opacity-50
					&::before
						@apply w-0  overflow-hidden
			a
				@apply flex items-center gap-3
				&::before
					content: '\f324'
					@apply font-awesome text-2xl font-light transition-all duration-300 w-6
	.item-box
		@apply overflow-hidden rounded-lg
		.content
			@apply bg-Primary-3/80 rounded-b-lg
			@screen lg
				transform: translateY(100%)
				@apply bg-transparent
			@apply transition-all duration-300 z-2
			.wrap
				@screen lg
					@apply opacity-0 transition-all duration-300
		&:hover
			.content
				@apply translate-y-0 bg-Primary-3/80
		.item-image
			&.active
				@apply border-Primary-2
	.swiper-pagination
		@apply static bg-[#D9D9D9]
		.swiper-pagination-progressbar-fill
			@apply bg-Primary-4
	.wrap-title-gradient-carousel
		@apply flex justify-between items-center
		.title
			-webkit-background-clip: text !important
			font-weight: 800
			letter-spacing: rr(-3.84px)
			text-transform: uppercase
			-webkit-text-fill-color: transparent
			-webkit-text-stroke-color: #969BA0
			-webkit-text-stroke-width: .1041666667rem
			@apply transition-all duration-300 xl:rem:text-[96px] lg:rem:text-[80px] md:text-[64px] text-[48px]
			&:hover
				-webkit-text-stroke-color: #003180
				@apply bg-Primary-3
	.wrap-slide-text
		@apply overflow-hidden w-full
		.scroll-container
			min-width: calc(300px * var(--quantity-mobile) - 300px)
			@screen md
				min-width: calc(31.25rem * var(--quantity-mobile) - 31.25rem)
			@apply relative flex w-full overflow-hidden place-content-end mt-10 xl:rem:h-[150px] h-[100px]
			.item
				left: 100%
				height: 100%
				@media (max-width: 767.98px)
					animation: autoRunLeftMobile calc(var(--quantity-mobile) * 2s) linear infinite
					animation-delay: calc( (var(--quantity-mobile) * 2s) / var(--quantity-mobile) * (var(--position-mobile) - 1) * -1)
				@screen md
					animation: autoRunLeft calc(var(--quantity-mobile) * 3s) linear infinite
					animation-delay: calc( (var(--quantity-mobile) * 3s) / var(--quantity-mobile) * (var(--position-mobile) - 1) * -1)
				@apply md:w-[31.25rem] absolute px-[8px] w-[300px]
				.title
					@apply text-center
@keyframes autoRunLeft
	from
		left: 100%
	to
		left: calc(31.25rem * -1)
@keyframes autoRunLeftMobile
	from
		left: 100%
	to
		left: calc(300px * -1)
