.fullpage-slide-thumb
	@apply fixed right-5 top-1/2 -translate-y-1/2 z-10 hidden xl:block
	&.color-white
		.swiper
			.item-thumb
				@apply text-white
	.swiper,.swiper-wrapper
		@apply h-[auto] overflow-visible
		.swiper-slide
			@apply rem:h-[46px] rem:w-[46px] mb-5 overflow-visible cursor-pointer #{!important}
			&:hover
				.item-thumb
					@apply opacity-100 bg-Primary-3 border-Primary-2 text-Primary-2 border
			&.swiper-slide-thumb-active
				.item-thumb
					@apply opacity-100 bg-Primary-3 border-Primary-2 text-Primary-2 border
		.item-thumb
			@apply w-full h-full rounded-full text-0 pointer-events-none transition-all duration-300 text-lg opacity-50 bg-transparent flex-center text-Primary-3

