.section-breadcrumb
	.breadcrumb-wrapper
		p
			@apply h-[calc(48/1920*100rem)] flex items-center overflow-auto
			a
				&:first-child
					@apply text-0 flex leading-[1] #{!important}
					&::before
						content: '\f015'
						@apply font-awesome text-lg font-light
			a,span
				@apply text-base text-neutral-500 whitespace-nowrap
			span.separator
				@apply text-0 inline-flex mx-3 leading-[1] #{!important}
				&::before
					content: "\f054"
					@apply font-awesome font-light text-base mx-1
.single-news
	.list-tab-primary
		@apply mt-0 #{!important}
		+list-tab
	.new-detail
		.date
			@apply justify-end w-full flex items-center gap-5 whitespace-nowrap
			&::after
				@apply hidden lg:block content-[""] h-[1px] w-full bg-neutral-300
		.content-details
			@apply lg:text-xl
			span
				@apply text-black font-bold
			p
				@apply text-neutral-950
			img
				@apply mx-auto flex-center
		.news-social
			@apply static xl:absolute rem:top-[30px] right-full mr-5 h-full
			ul
				@apply sticky flex flex-row xl:flex-col mt-4 xl:mt-0 gap-5 top-40
				li
					background: linear-gradient(180deg, #01A6F0 0%, #01699E 100%)
					@apply rounded-full rem:w-[40px] rem:h-[40px] flex-center gap-3 p-3
					i
						@apply text-Primary-2
					img
						@apply flex-center

.single-news-relative
	.swiper-slide
		@apply pb-8 border-b border-Primary-1
		.title
			@apply transition-all duration-300
		&:hover
			.image
				@apply duration-500 transition-all overflow-hidden
				img
					@apply scale-110
			.title
				@apply text-Primary-6
