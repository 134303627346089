.btn
	@apply cursor-pointer select-none transition-all duration-300
	// 
	&.btn-primary
		@apply h-12 flex-center px-3 py-3 text-lg font-semibold text-white gap-2 border border-white bg-transparent
		&.outline
			@apply outline-none #{!important}
			@apply bg-transparent text-Primary-1 border-Primary-1 hover:border-Primary-2 hover:text-Primary-2 #{!important}
			&:hover
				.icon-arrow
					&::before,&::after
						@apply text-Primary-2
			.icon-arrow
				&::before,&::after
					@apply text-Primary-1
		&.blue
			@apply bg-Primary-1 text-white border-Primary-1 hover:border-Primary-3 hover:bg-Primary-3 #{!important}
			.icon-arrow
				&::before,&::after
					@apply text-white
		&.secondary
			@apply bg-transparent text-Primary-1 border-Primary-1 hover:border-Primary-2 hover:text-Primary-2 #{!important}
			&:hover
				.icon-arrow
					&::before,&::after
						@apply text-Primary-2
			.icon-arrow
				&::before,&::after
					@apply text-Primary-1
		&:hover
			@apply border-Primary-2 text-Primary-2
			.icon-arrow
				&::before
					@apply translate-x-[125%]
				&::after
					@apply translate-x-0
		.icon-arrow
			@apply flex-center w-6 h-6 overflow-hidden relative
			@apply rem:pt-[3px]
			&::before
				@apply translate-x-0
			&::after
				@apply -translate-x-[125%]
			&::before,&::after
				content: '\f178'
				display: inline-block
				@apply font-light
				@apply absolute inline-block font-awesome transition-all duration-300 text-Primary-2
	&.btn-sw-1
		@apply w-16 h-16 flex-center rounded-full bg-Primary-3 hover:border-Primary-2 border-transparent border transition-all duration-300
		&::before,&::after
			@apply font-awesome text-2xl text-white font-light
		&.btn-prev
			&::before
				content: '\f053'
		&.btn-next
			&::before
				content: '\f054'
	&.btn-sw-2
		background: linear-gradient(67deg, #9FA1A3 29.78%, #FFF 54.04%, #CBCCCD 70.22%)
		@apply w-12 h-12 flex-center transition-all duration-300
		@apply relative
		&:hover
			background: linear-gradient(67deg, #ffde00 29.78%, #ffde00 54.04%, #ffde00 70.22%)
			&::before
				@apply text-white
			&::after
				background-color: #ffde00
		&::after
			@apply content-[''] absolute top-[2px] left-[2px] bottom-[2px] right-[2px] bg-white z-1 transition-all duration-300
		&::before
			@apply font-awesome text-2xl text-Neutral-300 font-light relative z-2 transition-all duration-300
		&.btn-prev
			&::before
				content: '\f053'
		&.btn-next
			&::before
				content: '\f054'
	&.btn-career
		@apply h-12 flex-center px-3 py-3 text-lg font-semibold text-white gap-2 border border-white bg-transparent
		&.outline
			@apply bg-transparent text-Primary-1 border-Primary-1 hover:border-Primary-2 hover:text-Primary-2 #{!important}
			&:hover
				.icon-arrow
					&::before,&::after
						@apply text-Primary-2
			.icon-arrow
				&::before,&::after
					@apply text-Primary-1
		&.blue
			@apply bg-Primary-1 text-white border-Primary-1 hover:border-Primary-3 hover:bg-Primary-3 #{!important}
			.icon-arrow
				&::before,&::after
					@apply text-white
		&:hover
			@apply border-Primary-2 text-Primary-2
			.icon-arrow
				&::before
					@apply translate-x-[125%]
				&::after
					@apply translate-x-0
		.icon-arrow
			@apply flex-center w-6 h-6 overflow-hidden relative
			@apply rem:pt-[3px]
			&::before
				@apply translate-x-0
			&::after
				@apply -translate-x-[125%]
			&::before,&::after
				content: '\f178'
				display: inline-block
				@apply font-light
				@apply absolute inline-block font-awesome transition-all duration-300 text-Primary-2
	&.btn-profile
		@apply py-3 px-5 flex-center gap-2 border-solid border-Primary-1 border
		@apply transition-all duration-500
		&:hover
			@apply bg-Primary-1
			span,
			.icon-profile
				@apply text-white
		span
			@apply text-Primary-1 text-lg font-semibold
		.icon-profile
			@apply text-Primary-1
			&::before
				content: '\f019'
				@apply inline-block font-awesome

.btn-style-main
	@apply w-full lg:w-auto cursor-pointer select-none transition-all duration-300
	@apply bg-Primary-1
	&.btn-primary
		@apply flex justify-center gap-2 relative z-2 py-3 px-5  w-fit overflow-hidden
		@apply text-white #{!important}
	&:hover
		@apply bg-Primary-2
		.icon
			&::before
				@apply translate-x-[125%]
			&::after
				@apply translate-x-0
	.text
		@apply flex items-end justify-center
		@apply text-white capitalize text-sm font-semibold py-3 px-6 gap-3
		@apply transition-all duration-300
	&::before
		content: ''
		@apply absolute top-0 left-0 w-full h-full -z-1
	.icon
		@apply rem:w-[16px] rem:h-[20px] relative overflow-hidden flex-shrink-0
		&::before
			@apply translate-x-0
		&::after
			@apply -translate-x-[125%]
		&::before,&::after
			content: '\f061'
			@apply font-awesome text-base inline-block w-full h-full absolute-full font-light
.btn-ctn
	&.btn-primary-doc,
	&.btn-primary-video
		@apply bg-Primary-1 text-white text-lg hover:bg-Primary-4
		.icon
			&::before
				content: '\f15b'
				@apply font-awesome text-white font-black
	&.btn-primary-video
		.icon
			&::before
				content: '\f04b'
				@apply  font-awesome text-white font-black
	&.btn-secondary
		@apply transition-all duration-300 text-lg font-semibold text-Primary-1 border-solid border border-Primary-1
		&:hover
			@apply text-[#FFDE00] border-[#FFDE00]

.swiper-wrapper-global
	.btn-prev
		background: red
		background-image: url('../img/arrow-left.png')
		transform: translate(-50%, -50%)
		@apply absolute top-1/2 left-0 z-100 block
	.btn-next
		@apply bg-white absolute top-1/2 right-0
		&::before
			@apply content-["\f054"] font-awesome

.btn-swiper-style-1
	.button-next,
	.button-prev
		border: 2px solid rgba(159, 161, 163, 1)
		@apply bg-white cursor-pointer absolute z-100 text-xl flex-center gap-3 py-2 px-4 font-light
	.button-next
		@apply  top-1/2 right-0
		@apply lg:translate-x-2/4 -translate-y-2/4
		&::before
			@apply content-['\f054'] font-awesome text-[#9FA1A3]
	.button-prev
		@apply top-1/2 left-0
		@apply lg:-translate-x-2/4 -translate-y-2/4
		&::before
			@apply content-['\f053'] font-awesome text-[#9FA1A3]

.btn-swiper-style-2
	.button-next,
	.button-prev
		border: 2px solid rgba(159, 161, 163, 1)
		@apply bg-white cursor-pointer absolute z-100 text-xl flex-center gap-3 py-2 px-4 font-light
	.button-next
		@apply  top-1/2 right-0
		@apply lg:translate-x-2/4 -translate-y-2/4 xl:rem:right-[-9%]
		&::before
			@apply content-['\f054'] font-awesome text-[#9FA1A3]
	.button-prev
		@apply top-1/2 left-0
		@apply lg:-translate-x-2/4 -translate-y-2/4 xl:rem:left-[-9%]
		&::before
			@apply content-['\f053'] font-awesome text-[#9FA1A3]

.btn-swiper-style-3
	@apply relative flex gap-3 sm:pb-10 lg:rem:pb-40
	.button-next,
	.button-prev
		border: 2px solid rgba(159, 161, 163, 1)
		@apply bg-white cursor-pointer  text-xl py-2 px-4 font-light
	.button-next
		&::before
			@apply content-['\f054'] font-awesome text-[#9FA1A3]
	.button-prev
		&::before
			@apply content-['\f053'] font-awesome text-[#9FA1A3]
	@media (max-width: 768px)
		.button-next,
		.button-prev
			@apply absolute rem:top-[200px]
		.button-next
			@apply -translate-x-2/4 -translate-y-2/4 rem:left-[55%] ml-4
		.button-prev
			@apply -translate-x-2/4 -translate-y-2/4 rem:right-[47%]


// .btn-swiper-style-4
// 	@apply relative 
// 	.btn-next,
// 		&::before
// 		@apply content-['\f054'] font-awesome
// 	.btn-prev
// 		&::before
// 			@apply content-['\f053'] font-awesome

.btn-swiper-style-4
	@apply flex-center flex-row-reverse mt-5 xl:mt-0
	.button-next,
	.button-prev
		@apply rounded-full text-white bg-[#01A6F0] font-normal cursor-pointer
		@apply flex-center gap-3 text-2xl font-thin rem:w-[64px] rem:h-[64px]
	.button-next
		@apply static ml-2 xl:absolute left-full top-1/2  xl:translate-x-2/4 z-100
		&::before
			@apply content-['\f054'] font-awesome
	.button-prev
		@apply static mr-2 xl:absolute right-full top-1/2  xl:-translate-x-2/4 z-100
		&::before
			@apply content-['\f053'] font-awesome
