
.section-project-1
	.list-tab-project
		@apply shadow-[rgba(99,99,99,0.2)_0px_2px_8px_0px]
		@apply px-5 lg:px-0
		@apply -translate-y-1/2 relative z-10 bg-white
		ul
			@apply w-full sm:w-auto overflow-x-auto overflow-hidden max-w-full scroll-primary
			@apply flex gap-5 lg:gap-10 pb-3
			li
				@apply rem:py-5 lg:rem:px-10 relative
				&:first-child
					@apply ml-auto
				&:last-child
					@apply mr-auto
				&:not(:last-child)::after
					@apply content-[""] absolute top-1/2 right-[-10px] xl:rem:right-[-20px] translate-y-[-50%] bg-neutral-300 block z-999 rem:w-[3px] rem:h-[20px]
				a
					@apply whitespace-nowrap rem:pb-6 relative text-black uppercase text-base font-bold
					&:hover
						@apply text-Primary-4
					&::after
						@apply content-[''] absolute bottom-0 left-0 w-0 rem:h-[4px] bg-Primary-1 transition-all duration-300
				&.active a
					@apply text-Primary-1
					&::after
						@apply w-full
				a:hover::after
					@apply w-full
	.content
		.desc
			p
				@apply pb-2
			span
				@apply font-bold
.section-project-2
	.select-title
		pointer-events: none
	.form-wrapper
		.form-group
			@apply lg:flex-1
			@apply cursor-pointer py-3 px-6 w-full bg-neutral-50 border-b border-neutral-500 border-s-orange-50
			&::after
				content: '\f0d7'
				@apply font-awesome absolute rem:top-[10px] rem:right-[10px] text-Primary-1 text-base font-black transition-all duration-300
			ul.select-options
				@apply overflow-y-scroll rem:max-h-[200px] hidden absolute top-full left-0 w-full bg-neutral-50 shadow-lg z-10
				li
					a
						@apply border-b border-neutral-200 text-base text-neutral-500 font-medium block py-2 px-4 hover:bg-gray-200
				&::-webkit-scrollbar-track
					-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3)
					background-color: #F5F5F5
				&::-webkit-scrollbar
					@apply rem:w-[6px] bg-neutral-200
				&::-webkit-scrollbar-thumb
					@apply bg-Primary-4 border-2 border-solid border-Primary-1
			&.active
				.select-options
					@apply block
.project-item-featured
	&.content-group
		.group-left
			img
				@apply duration-500 transition-all overflow-hidden
			&:hover img
				@apply scale-110
		.content-feature
			.list-feature
				@apply pb-3 flex justify-between border-b-[rgba(255,255,255,0.35)] border-b border-solid gap-5
				.desc
					@apply flex-1 text-right
				.caption
					@apply whitespace-nowrap
				.info
					@apply flex gap-4
.section-project-3
	.list-project
		img
			@apply duration-500 transition-all overflow-hidden
		&:hover img
			@apply scale-110
		&:hover h5
			@apply text-Primary-3
		.desc
			@apply flex items-center text-base font-normal text-neutral-950
			span
				@apply font-bold
	.pagination-wrapper
		ul
			@apply flex-center gap-5
			li
				a
					@apply pb-2 cursor-pointer text-neutral-500 rem:text-[20px] font-bold leading-1.3
					&:hover
						@apply text-[#009FDC] w-full border-b-3 border-solid border-b-[#009FDC]
				span
					@apply pb-2 cursor-pointer text-neutral-500 rem:text-[20px] font-bold leading-1.3
					&.active
						@apply w-full border-b-3 border-solid border-b-[#009FDC] text-[#009FDC]
				&.custom-hidden
					@apply hidden
	.loading-wrapper
		position: fixed
		top: 0
		left: 0
		right: 0
		bottom: 0
		background: rgba(255, 255, 255, 0.8)
		display: flex
		align-items: center
		justify-content: center
		z-index: 999
		.loading
			width: 50px
			height: 50px
			border: 5px solid #f3f3f3
			border-top: 5px solid #3498db
			border-radius: 50%
			animation: spin .5s linear infinite
@keyframes spin
	0%
		transform: rotate(0deg)
	100%
		transform: rotate(360deg)
