.single-career-1
	.box-info
		@apply bg-Neutral-50 p-10
		.wrapper-info
			img
				@apply rem:w-[360px] rem:h-[300px]
			.list-info
				@apply pb-3 flex-between border-b border-b-[#DCDCDC] gap-10
				.caption
					@apply text-black text-xl  font-bold whitespace-nowrap
				.desc
					@apply text-black text-xl font-normal text-right
				&:last-child .caption
					@apply text-Primary-1
	.sidebar-career
		.box-location
			.list-career
				@apply flex flex-col gap-4 p-6 border-solid border-[#D9D9D9] border
				.list-info
					@apply flex items-center gap-2
					.icon
						@apply text-Primary-1
						i
							@apply text-base font-normal
					.caption
						@apply text-Primary-1 text-base font-normal
					.date
						@apply font-normal text-black
#popup-apply
	@apply rem:max-w-[920px] w-full lg:p-10 md:p-5 p-[15px]
	.carousel__button.is-close
		background: linear-gradient(180deg, #01A6F0 0%, #01699E 100%)
		@apply cursor-pointer p-3 rem:w-[40px] rem:h-[40px] flex-center gap-2 top-0 rounded-none
	form
		@apply grid md:grid-cols-2 gap-x-5 gap-y-3
		.f-message
			@apply md:col-span-2
		label
			@apply hidden
		input,textarea
			@apply rem:h-[46px] border border-Neutral-200 px-5 py-3 font-medium text-Neutral-500 hocus:border-Primary-1 transition-all duration-300 w-full
		textarea
			@apply lg:rem:h-[152px] h-[140px]
