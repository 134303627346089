.section-home-banner
	.item-banner
		.banner-content
			h2
				-webkit-background-clip: text !important
				transition-duration: .3s
				transition-property: all
				transition-timing-function: cubic-bezier(.4,0,.2,1)
				background: transparent
				-webkit-text-fill-color: transparent
				-webkit-text-stroke-color: #fff
				-webkit-text-stroke-width: .1041666667rem
				@apply leading-tight text-[45px] md:rem:text-[96px] font-extrabold text-white transition-all duration-300 w-fit
				&:hover
					background: #ffde00
					-webkit-text-stroke-color: #ffde00
					@apply text-Primary-2
	.swiper-pagination
		.swiper-pagination-bullet
			@apply w-2 h-2 bg-white rounded-none
			&.swiper-pagination-bullet-active
				@apply bg-white
