.section-new-1
	.list-tab-primary
		+list-tab
	.wrapper-news
		.image
			@apply overflow-hidden
			img
				@apply w-full h-full object-cover duration-500 transition-all overflow-hidden
			&:hover img
				@apply scale-110
		.box-news
			@apply pb-8 border-b border-Primary-1
			&:hover
				img
					@apply scale-110
				.title
					@apply text-Primary-6 transition-all duration-300
	// .pagination-wrapper
	// 	ul
	// 		@apply flex-center gap-5
	// 		li
	// 			a,span
	// 				@apply text-xl font-bold text-[#7c7c7c] pb-2 border-b border-Primary-1 inline-block
	// 				&.current
	// 					@apply text-Primary-1
	// 				&:hover
	// 					@apply text-Primary-1
	// 			&.custom-hidden
	// 				@apply hidden
