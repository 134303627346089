.header-search-form
	visibility: hidden
	@apply fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 backdrop-blur-md z-[1000] flex justify-center items-center transition-all duration-300 opacity-0  pointer-events-none
	&.active
		@apply opacity-100 visible pointer-events-auto
	.wrap-form-search-product
		max-width: r(800px)
		margin: 0 auto
		@apply w-full
		.productsearchbox
			@apply w-full flex relative flex-wrap
			[class*="Module"]
				width: 100% !important
			input
				@apply flex-1 h-12.5 bg-white text-base px-7.5 outline-0 border-0 #{!important}
			button
				@apply w-12.5 flex items-center justify-center  outline-0 border-0 border-l border-[#E0E0E0] bg-Primary-6 text-Primary-2
				&::before
					content: '\f002'
					@apply font-awesome text-2xl font-light
