@layer components
	.line-white
		@apply w-full h-[1px] bg-white my-5
	.indicator-swipe
		@apply z-30 bg-black/20 w-[calc(144/19.2*1rem)] h-14 rounded-full flex justify-between items-center px-3 pointer-events-none
		@apply transition-all
		&:not(.active)
			@apply opacity-0
		svg
			@apply stroke-white
		.hand
			animation: moveSwipeGesture 2.25s infinite ease-in-out
			@apply md:w-12 md:h-12 w-10 h-10
	.item-news
		@apply grid lg:grid-cols-2 grid-cols-1
		&.small
			.title-28
				@apply text-xl
		.wrap-img
			@apply lg:pr-5
	.overlay-black
		background-color: rgba(0, 0, 0, 0.02)
		@apply fixed top-0 left-0 w-full h-full z-[1000] pointer-events-none opacity-0 transition-all duration-300
	.invalid-feedback
		@apply text-red-500 pt-2
	.pagination-wrapper
		ul
			@apply flex items-center justify-center gap-5
			li
				a
					@apply flex-center w-12.5 h-12.5 border border-Primary-1 text-center hover:bg-Primary-1 hover:text-Primary-2 transition-all duration-300
					&.current
						@apply bg-Primary-1 text-Primary-2
@keyframes moveSwipeGesture
	0%
		transform: translateX(-8px)
	50%
		transform: translateX(8px)
	100%
		transform: translateX(-8px)
