.swiper-full-current
	@screen xl
		&>.swiper-wrapper
			&>.swiper-slide
				.animation-fadeIn,.animation-fadeDown,.animation-fadeLeft,.animation-fadeRight
					animation: fadeClose 1s ease-out .5s both
				&.swiper-slide-active
					.animation-fadeIn
						animation: fadeIn 0.75s ease-out .5s both
					.animation-fadeRight
						animation: fadeRight 0.75s ease-out .5s both
					.animation-fadeLeft
						animation: fadeLeft 0.75s ease-out .5s both
					.animation-fadeDown
						animation: fadeDown 0.75s ease-out .5s both
					.delay-0
						animation-delay: 0s
					.delay-1
						animation-delay: 0.5s
					.delay-2
						animation-delay: 0.8s
					.delay-3
						animation-delay: 1.1s
					.delay-4
						animation-delay: 1.4s
					.delay-5
						animation-delay: 1.7s
					.delay-6
						animation-delay: 2s
body.fullpage
	@apply xl:overflow-hidden
.fullpage-swiper
	&>.swiper
		@screen xl
			height: 100vh
			.section-swiper
				height: 100vh
				@apply opacity-0 pt-20
				@apply pb-0
			.swiper-slide
				.section-swiper
					@apply opacity-0 transition-all duration-1000
				&.swiper-slide-active
					.section-swiper
						@apply opacity-100
		&> .swiper-wrapper
			display: block
			@screen xl
				display: flex
@keyframes fadeClose
	0%
		opacity: 1
	100%
		opacity: 0
@keyframes fadeIn
	0%
		opacity: 0
		transform: translateY(100%)
	100%
		opacity: 1
		transform: translateY(0)
@keyframes fadeDown
	0%
		opacity: 0
		transform: translateY(-100%)
	100%
		opacity: 1
		transform: translateY(0)

@keyframes fadeLeft
	0%
		opacity: 0
		transform: translateX(-100%)
	100%
		opacity: 1
		transform: translateX(0)

@keyframes fadeRight
	0%
		opacity: 0
		transform: translateX(100%)
	100%
		opacity: 1
		transform: translateX(0)
