.section-home-project
	.swiper-slide
		@apply h-auto w-fit #{!important}
	.wrap-button-slide
		.btn-prev,.btn-next
			@apply top-[40%]
	.item-project-featured
		@apply flex flex-col justify-end h-full relative
		&::before
			content: ''
			background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.60) 100%)
			@apply absolute  pointer-events-none z-1 h-[100px] bottom-0 left-0 w-full
		&:hover
			.content
				@apply translate-y-0 bg-Primary-3/80
				.wrap
					@apply opacity-100
		.img
			@apply rem:max-w-[480px] rem:max-h-[640px]
			img
				@apply max-w-full max-h-full
		.content
			@apply bg-Primary-3/80
			@screen lg
				transform: translateY(var(--height-item-var-height))
				@apply bg-transparent
			@apply transition-all duration-300 z-2
			.wrap
				@screen lg
					@apply opacity-0 transition-all duration-300
	.indicator-swipe
		@apply right-[15px] lg:right-25 top-0 absolute -translate-y-1/2

